<template>
  <div class="boxs">
    <floatingSidebar></floatingSidebar>
    <!-- @cancel='onCancelModal' -->
    <a-modal v-model="videoModalTrue" :footer="null" :width="1200">
      <div class="KsbClassInfoTitle">{{ KsbClassInfoTitle }}</div>
      <div class="video-box">
        <el-col :span="24">
          <el-col :span="18">
            <div class="prism-player" id="J_prismPlayer"></div>
          </el-col>
          <el-col :span="6">
            <div class="el-menu-vertical-demo-box">
              <el-menu
                class="el-menu-vertical-demo"
                :default-active="defaultActive"
                :unique-opened="uniqueOpened"
                @open="onVideoOpen"
                @select="onVideoSelect"
              >
                <el-submenu
                  :key="index"
                  :index="JSON.stringify(item.bookID)"
                  v-for="(item, index) in videoList"
                >
                  <template slot="title">
                    <span>
                      {{
                        item.bookName.lastIndexOf("_") == -1
                          ? item.bookName
                          : item.bookName.substr(
                              0,
                              item.bookName.lastIndexOf("_")
                            )
                      }}</span
                    >
                  </template>
                  <el-menu-item-group>
                    <el-menu-item
                      :key="indexs"
                      :index="JSON.stringify(items.names[0].id)"
                      v-for="(items, indexs) in videoSublevelList"
                    >
                      {{ items.chapterName }}</el-menu-item
                    >
                  </el-menu-item-group>
                </el-submenu>
              </el-menu>
            </div>
          </el-col>
        </el-col>
      </div>
    </a-modal>
    <div class="crumbs-text">
      <span @click="onCrumbs(0)">首页</span>
      <a-icon type="right" />
      <span @click="onCrumbs(1)">班次详情</span>
    </div>
    <div class="">
      <div class="typepage">
        <subject @subjects="onSubjects" :subjectData="subjectData"></subject>

        <div class="contrast" v-if="visibles" ref="modal">
          <a-modal
            v-model="visibles"
            @ok="handleOk"
            :footer="null"
            :width="1200"
            @cancel="zhezhao"
            title="请选择班次"
            :getContainer="() => $refs.modal"
          >
            <div class="currently">
              <span>当前科目</span> ：{{ buyList[0].AppCName }}
            </div>
            <div class="classesdatasBOX">
              <div class="classesdatasrm">
                <span>热门班次</span>
              </div>
              <div class="classesdatas">
                <div
                  class="classesdatas_a"
                  v-for="(item, index) in buyList"
                  :key="index"
                  v-if="item.Group == 0"
                >
                  <div
                    class="classesdatas_b"
                    :class="
                      item.VerName == '精讲优选班' ||
                      item.VerName == '专项提分班'
                        ? 'classesdatas_bs'
                        : 'classesdatas_b'
                    "
                  >
                    <div
                      class="classesdatasImg"
                      v-if="
                        item.VerName == '精讲优选班' ||
                        item.VerName == '专项提分班'
                      "
                    >
                      <img src="../assets/huo.png" alt="" />
                    </div>
                    <div class="classesdatasVerName">{{ item.VerName }}</div>
                    <div class="astrict">
                      <div class="astrict_a">
                        ￥{{
                          item.VerName == "影像高手" ||
                          item.VerName == "影像高手高高手"
                            ? item.SubPrice + "起"
                            : item.Price
                        }}/{{ item.FixedEndTime == 1 ? "考季" : "年" }}
                      </div>
                      <!-- <div>198/考季</div> -->
                      <div class="astrict_b" @click="viewdetails(item, index)">
                        <span>查看详情</span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-for="(VerJsonlist, i) in JSON.parse(item.VerJson).vnLabel"
                    :key="i"
                    class="contrast_bcTitleBox"
                  >
                    <div v-if="VerJsonlist != '试听'" class="contrast_b_bc">
                      <div class="contrast_bc_title">✔</div>
                      <div class="bcbanes">
                        {{ VerJsonlist }}
                      </div>
                    </div>
                  </div>
                  <div class="immediately" @click="purchases(item)">
                    立即购买
                  </div>
                </div>
              </div>
              <div class="classesdatasrm">
                <span>其它班次</span>
              </div>
              <div class="classesdatas">
                <div
                  class="classesdatas_a"
                  v-for="(item, index) in buyList"
                  :key="index"
                  v-if="item.Group != 0"
                >
                  <div
                    class="classesdatas_b"
                    :class="
                      item.VerName == '精讲优选班'
                        ? 'classesdatas_bs'
                        : 'classesdatas_b'
                    "
                  >
                    <div
                      class="classesdatasImg"
                      v-if="item.VerName == '精讲优选班'"
                    >
                      <img src="../assets/huo.png" alt="" />
                    </div>
                    <div class="classesdatasVerName">{{ item.VerName }}</div>
                    <div class="astrict">
                      <div class="astrict_a">
                        ￥{{
                          item.VerName == "影像高手" ||
                          item.VerName == "影像高手高高手"
                            ? item.SubPrice + "起"
                            : item.Price
                        }}

                        /{{ item.FixedEndTime == 1 ? "考季" : "年" }}
                      </div>
                      <!-- <div>198/考季</div> -->
                      <div class="astrict_b" @click="viewdetails(item, index)">
                        <span>查看详情</span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-for="(VerJsonlist, i) in JSON.parse(item.VerJson).vnLabel"
                    :key="i"
                    class="contrast_bcTitleBox"
                  >
                    <div v-if="VerJsonlist != '试听'" class="contrast_b_bc">
                      <div class="contrast_bc_title">✔</div>
                      <div class="bcbanes">
                        {{ VerJsonlist }}
                      </div>
                    </div>
                  </div>
                  <div class="immediately" @click="purchases(item)">
                    立即购买
                  </div>
                </div>
              </div>
            </div>
          </a-modal>
        </div>
        <div ref="modal" v-if="visible" class="contrasts">
          <a-modal
            v-model="visible"
            @ok="handleOk"
            :footer="null"
            :width="782"
            @cancel="zhezhao"
            :getContainer="() => $refs.modal"
          >
            <template v-if="paymentShow">
              <div class="zzzfs">购买班次</div>
              <div class="zzzf" @click="(visible = false), (visibles = true)">
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="zfvn">
                <div>
                  当前科目: <span>{{ itemdata.AppCName }}</span>
                </div>
                <div>
                  开通班次: <span> {{ itemdata.VerName }}</span>
                </div>
                <div>
                  用户账号:<span>{{ usernames }}</span>
                </div>
              </div>

              <div class="tcbox">
                <div class="yfje">
                  应付金额：<span class="jine">{{ Price }}</span>
                </div>
                <div class="imgsewm">
                  <span>
                    <div
                      class="wxzf"
                      id="qrcode"
                      ref="qrcode"
                      v-if="visible"
                    ></div>
                    <div class="buyte">
                      <img src="../assets/Lark20201020-151019.png" alt="" />
                      微信支付
                    </div>
                  </span>
                  <span class="zfb"
                    ><img
                      class="qqq"
                      src="../assets/Lark20201020-141431.png"
                      alt=""
                    />
                    <div class="zhifubao" @click="zfb">支付宝支付</div>
                  </span>
                </div>
              </div>
            </template>

            <template v-else>
              <div class="checkboxGroupBox">
                <div class="checkboxGroup-title">请选择你要充值的课程</div>
                <div class="checkboxGroup-box">
                  <div class="group-box">
                    <el-checkbox-group
                      v-model="checkedCities"
                      @change="handleCheckedCitiesChange"
                    >
                      <div
                        v-for="(item, index) in SubBookList"
                        :key="index"
                        class="checkboxGroup"
                      >
                        <el-checkbox :label="item.id" :key="index">
                          <span>{{ onSubstring(item.name) }}</span>
                          <span v-if="item.isVip" class="isvipClor"
                            >已开通</span
                          >
                          <div class="group-text">
                            ￥{{ itemdata.DiscountPrice }}
                          </div>
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="checkboxGroup-box borede">
                  <div class="group-box group-flex">
                    <!-- {{totalPrices}} -->
                    <el-checkbox
                      :indeterminate="isIndeterminate"
                      v-model="checkAll"
                      @change="handleCheckAllChange"
                      >全选</el-checkbox
                    >
                    <div class="checkbox-right">
                      <div class="SubPrice">￥原价{{ totalPrices }}</div>
                      <div class="totalPrices">
                        ￥<span>{{ totalPrices }}</span>
                      </div>
                      <div class="buy-btn" @click="onVideoPlayerBug">购买</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </a-modal>
        </div>

        <a-icon type="double-left" class="tbia" v-if="xsyx" />
        <a-icon type="double-right" class="tbiarig" v-if="xsyx" />

        <a-tabs
          :default-active-key="sss"
          :tab-position="mode"
          @prevClick="callback"
          @nextClick="callback"
          @tabClick="dianjhq"
          v-model="actv"
        >
          <!-- v-if="item.ExtJson != null && item.ExtJson?item.ExtJson.indexOf('xieyiname')==-1:'' && item.ExtJson?JSON.parse(item.ExtJson).imgurl!='' && item.ExtJson?item.ExtJson!='{}':''  :''" -->
          <a-tab-pane
            v-for="(item, index) in buyList"
            :key="index"
            :tab="item.VerName"
            defaultActiveKey="1"
            @tabClick="dianjhq(item)"
          >
            <div class="left" v-if="item.ExtJson">
              <!-- <div class="banner-dd" v-if="item.ExtJson.bannerUrl.length < 5"></div> -->
              <div class="banner-b">
                <img :src="item.ExtJson.imgurl" class="xshiimg" />
              </div>
            </div>
            <div v-else class="left lewu">
              <div class="tubiaoya">
                <a-icon type="vertical-align-top" />
              </div>
              <div>班次详情图正在制作中，请您联系客服咨询购买喔~~~</div>
            </div>
            <div class="right">
              <div class="banci">
                <div class="titlename">{{ item.VerName }}</div>
                <div class="line-a"></div>
                <div class="fuwu namedandu">{{ item.AppCName }}</div>
                <div class="fuwu">{{ item.Content }}</div>
                <div class="fuwu">
                  <span
                    v-for="(VerJsonlist, i) in JSON.parse(item.VerJson).vnLabel"
                    :key="i"
                    class="fuwu-box"
                  >
                    <span class="fuwu-text">
                      {{ VerJsonlist }}
                    </span>
                  </span>
                </div>

                <div class="fuwu">
                  <span><img src="../assets/img/时间.png" /></span>
                  <span>
                    <span class="yxtime">有效期：</span
                    >{{ item.EndTime.slice(0, 10) }}</span
                  >
                </div>
                <div class="line-b"></div>

                <div>
                  <span class="ljgmyas"
                    >￥
                    {{
                      item.VerName == "影像高手" ||
                      item.VerName == "影像高手高高手"
                        ? item.SubPrice
                        : item.Price
                    }}</span
                  >
                  <div class="ljgmyas-right">
                    <span class="watchVideo" @click="onPlayVideo(item)"
                      >试听课程</span
                    >
                    <span
                      class="ljgmya"
                      @click="purchase(item)"
                      v-if="item.isVip == 0"
                      >立即购买</span
                    >
                    <span class="ljgmya ygmai" v-if="item.isVip == 1"
                      >已购买</span
                    >
                  </div>
                </div>
              </div>
              <div class="aa">
                <div class="aa-title">在线学习只要3秒</div>
                <div class="aa-q">
                  <img src="../assets/img/购物.png" />
                  <ul>
                    <li class="aa-w">购买课程</li>
                    <li class="aa-e">
                      考试宝典网提供了支付宝支付、网银支付、手机移动支付等在线支付方式，
                      均安全有效，您可以选择佳的支付方式进行购买。
                    </li>
                  </ul>
                </div>
                <div class="aa-q">
                  <img src="../assets/img/下载(1).png" />
                  <ul>
                    <li class="aa-w">下载客户端</li>
                    <li class="aa-e">
                      支持手机APP和平板电脑端苹果和安卓用户均可从各应用市场下载随时随地想学就学
                    </li>
                  </ul>
                </div>
                <div class="aa-q">
                  <img src="../assets/img/数据源.png" />
                  <ul>
                    <li class="aa-w">轻松上课</li>
                    <li class="aa-e">
                      适配各种电脑机型的各种浏览器浏览器和手机的数据互通，让换设备无忧无虑
                    </li>
                  </ul>
                </div>
              </div>
              <div class="qrCode_box" @click="onClickCard">
                <img
                  class="qrCode_box_img"
                  src="../assets/download/wechat_qrcode.png"
                />
                <ul class="qrCode_box_ul">
                  <div class="qrCode_box_list">
                    <img
                      src="../assets/img/window.png"
                      style="margin-right: 5px"
                    />电脑版
                  </div>
                  <div class="qrCode_box_list">
                    <img
                      src="../assets/img/Android.png"
                      style="margin-right: 5px"
                    />安卓版
                  </div>
                  <div class="qrCode_box_list">
                    <img
                      src="../assets/img/iOS.png"
                      style="margin-right: 5px"
                    />iphone版
                  </div>
                  <div class="qrCode_box_list">
                    <img
                      src="../assets/img/cyc.png"
                      style="margin-right: 5px"
                    />微信扫一扫 快速咨询
                  </div>
                </ul>
                <div class="clickDownload" @click="onClickDownload">
                  点击下载
                </div>
              </div>
              <div class="footer-right" @click="onCustomerService">
                <div class="footer-right-text">
                  报名咨询热线<span class="footer-right-text-span"
                    >(早8:00-晚22:00)</span
                  >
                </div>
                <div class="contact">
                  <div class="contact-left">
                    <ul class="contact-left-ul">
                      <li class="contact-left-li">微信&nbsp;602020500</li>
                      <li class="contact-left-li">咨询&nbsp;4001856601</li>
                      <li class="contact-left-li">售后&nbsp;4007278800</li>
                    </ul>
                  </div>
                  <div class="contact-right">
                    <img src="../assets/img/customer.png" />
                  </div>
                </div>
              </div>
            </div>
            <!-- Content of tab {{ i }} -->
          </a-tab-pane>
        </a-tabs>

        <div class="example" v-show="kmLoadingShow">
          <a-spin />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var that;
import axios from "axios";
import {
  pricelist,
  getOrderNumber,
  userVipinfo,
  getCacheAppVnList,
  videoCatalogue,
  videoPlayUrl,
  getAppKsbClass,
  getClassID,
  freeFegist,
} from "../api/api.js";
import QRCode from "qrcodejs2";
import subject from "../components/subject.vue";
import floatingSidebar from "../components/floatingSidebar.vue";
var qrcode;
export default {
  name: "Home",
  inject: ["reload"],
  components: {
    subject,
    QRCode,
    floatingSidebar,
  },
  data() {
    return {
      mode: "top",
      sss: 0,
      AppID: "",
      buyList: [],
      visible: false,
      wxUrl: "",
      Price: "",
      itemdata: [],
      subjectData: {},
      xsyx: true,
      token: 0,
      videoModalTrue: false,
      uniqueOpened: true, //是否只保持一个子菜单的展开
      videoList: [], // 视频列表
      videoSublevelList: [], //视频列表子级
      KsbClassInfoTitle: "",
      bookID: "",
      player: null, //视频播放器
      defaultActive: 0,
      KsbClassID: 0, //系列id
      KsbClassID2: 0, //类别id
      appPName: "",
      showSubject: false,
      kmLoadingShow: false, //懒加载
      visibles: false,
      actv: 0,
      //影响高手
      paymentShow: false,
      SubBookList: [],
      SubBookListId: [],
      totalPrices: 0,
      checkAll: false,
      checkedCities: [],
      isIndeterminate: false,
      usernames: "",
    };
  },
  created() {
    console.log(window.location.host + "/details/");
    this.$parent.xqing = true;

    if (JSON.parse(sessionStorage.getItem("subjectChoose"))) {
      this.subjectData = JSON.parse(sessionStorage.getItem("subjectCache"));
      this.AppID = JSON.parse(sessionStorage.getItem("subjectChoose")).AppID;
      this.pricelist();
    } else {
      // this.getFreeFegist();
      // this.getClassIDList();
      this.logins();
    }
  },
  mounted() {
    that = this;
  },
  methods: {
    logins() {
      if (sessionStorage.getItem("cellphone")) {
        console.log("已登录");
      } else {
        //没有登录执行到这一步，请求免费试用接口
        let data = {
          agentCode: "100088-1",
          clientType: "武汉神州-网校版",
        };
        //没有登录请求免费试用接口，并判断缓存是否有免费试用的guid信息，没有请求接口，有从缓存获取
        if (sessionStorage.getItem("freeFegistData")) {
          //获取缓存,储存在loginData
          sessionStorage.setItem(
            "loginData",
            sessionStorage.getItem("freeFegistData")
          );
          sessionStorage.setItem("logging", 0);
        } else {
          //请求接口
          freeFegist(data).then((res) => {
            if (res.data.status == 200) {
              sessionStorage.setItem(
                "freeFegistData",
                JSON.stringify(res.data.data)
              ); //把免费试用信息存储
              sessionStorage.setItem(
                "loginData",
                JSON.stringify(res.data.data)
              );
              sessionStorage.setItem("logging", 1);
              this.getClassIDList();
            }
          });
        }
      }
    },
    getClassIDList() {
      var data = {
        appEname: this.$route.params.pathMatch,
      };
      getClassID(data).then((res) => {
        var subjectData = {
          KsbClassID: "",
          MediClassID: "",
          KsbClassID2: "",
          KsbClass: "",
          Childs: "",
          appPName: "",
        };
        this.AppID = res.data.data[0].AppID;

        var km = res.data.data[0];
        this.subjectChoose = {
          AppEName: km.AppEName,
          AppID: km.AppID,
          KsbClassName: km.appName,
          Name: km.appName,
        };
        // sessionStorage.setItem("subjectData", JSON.stringify(subjectData));
        sessionStorage.setItem(
          "subjectChoose",
          JSON.stringify(this.subjectChoose)
        );
        sessionStorage.setItem(
          "subjectCache",
          JSON.stringify(this.subjectChoose)
        );
        this.pricelist();
      });
    },
    purchase(item) {
      this.visibles = true;
      // document.getElementById("qrcode").innerHTML = "";
      return;
      var that = this;
      this.itemdata = item;
      var itemdata = item;
      this.Price = itemdata.Price;
      if (sessionStorage.getItem("cellphone")) {
        let loginData = JSON.parse(sessionStorage.getItem("loginData"));
        let urls = `http://116.62.79.97/mypro?url=http://deep-slb-pay.ksbao.com/api/getOrderNumber?userID=${loginData.userID}&appEName=${this.itemdata.AppEName}&equipment=hej_iPhone&agentCode=100088-1&vn=${this.itemdata.AppVer}&payType=weixin_qrcode&returnUrl=http://hzstg.ksbao.com/tz`;
        this.axios({
          url: urls,
          method: "post",
        }).then((res) => {
          var wxUrl = res.data.data.wxUrl;
          //
          qrcode = new QRCode("qrcode", {
            width: 125, // 二维码宽度
            height: 125, // 二维码高度
            text: wxUrl,
          });
        });

        this.visible = true;
        var that = this;
        var data = {
          appEName: itemdata.AppEName,
          guid: JSON.parse(sessionStorage.getItem("loginData")).guid,
        };
        var kg = true;
      } else {
        this.$parent.passwordLogin = true;
      }
    },

    purchases(item) {
      var that = this;
      this.itemdata = item;
      this.usernames = sessionStorage.getItem("danci");
      var itemdata = item;
      this.Price = itemdata.Price;
      if (item.VerName == "影像高手" || item.VerName == "影像高手高高手") {
        this.SubBookList = item.SubBookList;
        this.checkedCities.push(item.SubBookList[0].id); //默认值
        this.totalPrices = item.DiscountPrice;
        this.choosePriceShow = true; //弹窗
        this.paymentShow = false; //是否显示支付
        this.visible = true;
      } else {
        this.onRecharge();
      }
    },
    onRecharge(type) {
      console.log("type", type);
      if (sessionStorage.getItem("cellphone")) {
        this.paymentShow = true; //是否显示支付
        var itemdata = this.itemdata;
        let loginData = JSON.parse(sessionStorage.getItem("loginData"));
        var data = {
          //this.$store.getters.userInfo.userID
          userID: loginData.userID,
          appEName: this.itemdata.AppEName,
          equipment: "hej_iPhone",
          agentCode: "100088-1",
          vn: this.itemdata.AppVer, //班次
          payType: "weixin_qrcode", //支付类型
          returnUrl: window.location.href, //成功跳转
          // returnUrl:"http://hzs.ksbao.com/payCallback?payback=1"
        };
        if (type != undefined) {
          data.payType = type;
        }
        if (
          this.itemdata.VerName == "影像高手" ||
          this.itemdata.VerName == "影像高手高高手"
        ) {
          data["branchBookList"] = JSON.stringify(this.checkedCities);
        }
        getOrderNumber(data).then((res) => {
          if (res.data.status == 200) {
            var wxUrl = res.data.data.wxUrl;
            console.log("wxUrl", wxUrl);
            qrcode = new QRCode("qrcode", {
              width: 125, // 二维码宽度
              height: 125, // 二维码高度
              text: wxUrl,
            });
          }
        });
        this.visible = true;
        this.visibles = false;
        var data = {
          appEName: itemdata.AppEName,
          guid: JSON.parse(sessionStorage.getItem("loginData")).guid,
        };
        var kg = true;
      } else {
        this.$parent.passwordLogin = true;
      }
    },
    zfb() {
      let loginData = JSON.parse(sessionStorage.getItem("loginData"));
      var data = {
        //this.$store.getters.userInfo.userID
        userID: loginData.userID,
        appEName: this.itemdata.AppEName,
        equipment: "hej_iPhone",
        agentCode: "100088-1",
        vn: this.itemdata.AppVer, //班次
        payType: "alipay_web", //支付类型
        returnUrl: window.location.href, //成功跳转
        // returnUrl:"http://hzs.ksbao.com/payCallback?payback=1"
      };
      if (
        this.itemdata.VerName == "影像高手" ||
        this.itemdata.VerName == "影像高手高高手"
      ) {
        data["branchBookList"] = JSON.stringify(this.checkedCities);
      }
      getOrderNumber(data).then((res) => {
        if (res.data.status == 200) {
          window.location.href = res.data.data.alipay;
        }
      });

      return;
      let urls = `http://116.62.79.97/mypro?url=http://deep-slb-pay.ksbao.com/api/getOrderNumber?userID=${loginData.userID}&appEName=${this.itemdata.AppEName}&equipment=hej_iPhone&agentCode=100088-1&vn=${this.itemdata.AppVer}&payType=alipay_web&returnUrl=${window.location.href}`;
      this.axios({
        url: urls,
        method: "post",
      }).then((res) => {
        window.location.href = res.data.data.alipay;
      });
    },
    //影像高手 影像高手高高手
    onVideoPlayerBug() {
      var that = this;
      this.Price = this.totalPrices;
      this.onRecharge();
    },
    onSubstring(text) {
      var str = text.substring(
        0,
        text.lastIndexOf("_") != -1 ? text.lastIndexOf("_") : text.length()
      );
      return str;
    },
    //全选
    handleCheckAllChange(val) {
      var SubBookList = this.SubBookList;
      var cities = [];
      SubBookList.forEach((item, index) => {
        cities.push(item.id);
      });
      this.checkedCities = val ? cities : [];
      this.isIndeterminate = false;

      this.totalPrices =
        this.checkedCities.length * this.itemdata.DiscountPrice;
    },
    //选择框
    handleCheckedCitiesChange(value) {
      this.totalPrices = value.length * this.itemdata.DiscountPrice;
      let checkedCount = value.length;
      this.checkAll = this.SubBookList.length === this.checkedCities.length;
      this.isIndeterminate =
        this.SubBookList.length === this.checkedCities.length;
    },
    //点击查看详情
    viewdetails(item, index) {
      console.log(item, index);
      this.visibles = false;
      this.actv = index;
    },

    //点击右边卡片
    onClickCard: function () {
      window.open("http://ksbao.com/szxx");
    },
    //点击下载
    onClickDownload: function () {
      window.location.href =
        "http://agent-files.ksbao.com/100088-1/newksbao.exe";
    },
    onGetClass(buyList) {
      let data = {
        SessionKey: "1C79CE6C3037EA1594CEA4CE3653C08B",
        appEName: JSON.parse(sessionStorage.getItem("subjectChoose")).AppEName,
      };
      getAppKsbClass(data).then((res) => {
        var simpc = res.data.data[0].KsbClassName;
        var Childs = "";
        var childsName = "";
        if (res.data.data[0].Childs != "") {
          Childs = res.data.data[0].Childs[0].KsbClassName;
          childsName = Childs.substr(0, Childs.indexOf("_"));
        }

        var simpclass = simpc.substr(0, simpc.indexOf("_"));

        for (let i in buyList) {
          //介绍
          buyList[i].Content = buyList[i].Content.replace(
            /专业侧重点/g,
            "科目要点"
          );
          buyList[i].Content = buyList[i].Content.replace(/重/g, "要");
          buyList[i].Content = buyList[i].Content.replace(/名师/g, "老师");
          buyList[i].Content = buyList[i].Content.replace(/真题/g, "试题");
          buyList[i].Content = buyList[i].Content.replace(/终极/g, "");
          buyList[i].Content = buyList[i].Content.replace(/不过都难！/g, "");
          buyList[i].Content = buyList[i].Content.replace(/名医/g, "医生");
          buyList[i].Content = buyList[i].Content.replace(/首选/g, "优选");
          buyList[i].Content = buyList[i].Content.replace(/王牌/g, "精品");
          buyList[i].Content = buyList[i].Content.replace(/权威/g, "");
          //标签
          buyList[i].VerJson = buyList[i].VerJson.replace(/重/g, "要");
          buyList[i].VerJson = buyList[i].VerJson.replace(/名师/g, "老师");
          buyList[i].VerJson = buyList[i].VerJson.replace(/真题/g, "试题");
          buyList[i].VerJson = buyList[i].VerJson.replace(/终极/g, "");
          buyList[i].VerJson = buyList[i].VerJson.replace(/不过都难!/g, ",");
          buyList[i].VerJson = buyList[i].VerJson.replace(/名医/g, "医生");
          buyList[i].VerJson = buyList[i].VerJson.replace(/首选/g, "优选");
          buyList[i].VerJson = buyList[i].VerJson.replace(/王牌/g, "精品");
          buyList[i].VerJson = buyList[i].VerJson.replace(
            /专业侧重点/g,
            "科目要点"
          );

          //正高  副高
          if (simpclass == "正高卫生职称" || simpclass == "副高卫生职称") {
            //正高副高基础题库
            if (buyList[i].VerName == "基础题库") {
              buyList[i].ExtJson.imgurl = require("../assets/czg/G_jctk.jpg");
            }
            //正高副高基础题库
            if (buyList[i].VerName == "专项提分班") {
              buyList[i].ExtJson.imgurl = require("../assets/czg/G_zxtf.jpg");
            }
            //正高副高基础题库
            if (buyList[i].VerName == "全程特训班") {
              buyList[i].ExtJson.imgurl = require("../assets/czg/G_qctxb.jpg");
            }
            if (buyList[i].VerName == "评审手把手指导班") {
              buyList[i].ExtJson.imgurl = require("../assets/czg/G_sbszdb.jpg");
            }
            // 2022/2/18
            if (buyList[i].VerName == "专属私教冲刺班") {
              buyList[i].ExtJson.imgurl = require("../assets/czg/G_hl_ccb.jpg");
            }
            if (buyList[i].VerName == "专属私教强化班") {
              buyList[i].ExtJson.imgurl = require("../assets/czg/G_hl_qhb.jpg");
            }
          }
          //中级
          if (simpclass == "中级卫生职称") {
            //中级基础题库
            if (
              buyList[i].VerName == "基础题库" ||
              buyList[i].VerName == "精讲课程班" ||
              buyList[i].VerName == "强化提升班" ||
              buyList[i].VerName == "双季畅学班"
            ) {
              buyList[i].ExtJson.imgurl = require("../assets/20221121/cz.jpg");
            }
            if (buyList[i].VerName == "专项提分班") {
              buyList[i].ExtJson.imgurl = require("../assets/czg/Z_zxtfb.jpg");
            }
            if (buyList[i].VerName == "全程特惠班") {
              buyList[i].ExtJson.imgurl = require("../assets/czg/Z_qcthb.jpg");
            }
            // 2022/2/18 护理类
            if (childsName == "主管护师") {
              if (buyList[i].VerName == "专属私教冲刺班") {
                buyList[
                  i
                ].ExtJson.imgurl = require("../assets/czg/Z_hl_ccb.jpg");
              }
              if (buyList[i].VerName == "专属私教强化班") {
                buyList[
                  i
                ].ExtJson.imgurl = require("../assets/czg/Z_hl_qhb.jpg");
              }
            } else {
              if (buyList[i].VerName == "专属私教冲刺班") {
                buyList[
                  i
                ].ExtJson.imgurl = require("../assets/czg/C_lc_ccb.jpg");
              }
              if (buyList[i].VerName == "专属私教强化班") {
                buyList[
                  i
                ].ExtJson.imgurl = require("../assets/czg/C_lc_qhb.jpg");
              }
            }
            buyList[i].Content = buyList[i].Content.replace(
              /一次通关/g,
              "顺利晋升"
            );
          }
          //初级
          if (simpclass == "初级卫生职称") {
            if (
              buyList[i].VerName == "基础题库" ||
              buyList[i].VerName == "精讲课程班" ||
              buyList[i].VerName == "强化提升班" ||
              buyList[i].VerName == "双季畅学班"
            ) {
              buyList[i].ExtJson.imgurl = require("../assets/20221121/cz.jpg");
            }

            if (buyList[i].VerName == "全程特惠班") {
              buyList[i].ExtJson.imgurl = require("../assets/czg/C_qcthb.jpg");
            }
            if (buyList[i].VerName == "专项提分班") {
              buyList[i].ExtJson.imgurl = require("../assets/czg/C_zxtfb.jpg");
            }
            // 2022/2/18
            if (buyList[i].VerName == "专属私教冲刺班") {
              buyList[i].ExtJson.imgurl = require("../assets/czg/C_hl_ccb.jpg");
            }
            if (buyList[i].VerName == "专属私教强化班") {
              buyList[i].ExtJson.imgurl = require("../assets/czg/C_hl_qhb.jpg");
            }
            buyList[i].Content = buyList[i].Content.replace(
              /一次通关/g,
              "顺利晋升"
            );
          }
          //住院医师招录
          if (simpclass == "住院医师招录") {
            // if (buyList[i].VerName == '基础题库') {
            // 	buyList[i].ExtJson.imgurl = require('../assets/zy/Gp_jctk.jpg')
            // }
          }
          //住院医师规培
          if (simpclass == "住院医师规培") {
            if (buyList[i].VerName == "基础题库") {
              buyList[i].ExtJson.imgurl = require("../assets/zy/Gp_jctk.jpg");
            }
            if (buyList[i].VerName == "精讲课程班") {
              buyList[i].ExtJson.imgurl = require("../assets/zy/Gp_jjkcb.jpg");
            }
            if (buyList[i].VerName == "特训密卷") {
              buyList[i].ExtJson.imgurl = require("../assets/zy/Gp_txmj.jpg");
            }
            if (buyList[i].VerName == "专项提分班") {
              buyList[i].ExtJson.imgurl = require("../assets/zy/Gp_zxtfb.jpg");
            }
          }
          //医疗招聘
          if (simpclass == "医疗招聘") {
            if (buyList[i].VerName == "精品题库班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/career/ylzp/Y_jptk.jpg");
            }
            if (buyList[i].VerName == "精讲课程班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/career/ylzp/Y_jpkc.jpg");
            }
          }
          //硕士研究生
          if (simpclass == "硕士研究生") {
            if (buyList[i].VerName == "全程提分班") {
              buyList[i].Content = buyList[i].Content.replace(
                /一次通关/g,
                "助力备考!"
              );
            }
            if (buyList[i].VerName == "全程定制班") {
              buyList[i].Content = buyList[i].Content.replace(
                /一次通关/g,
                "考试顺利"
              );
            }
          }
          //执业兽医
          if (simpclass == "执业兽医") {
            if (
              buyList[i].VerName == "精品题库班" ||
              buyList[i].VerName == "精讲课程班" ||
              buyList[i].VerName == "全程提分班"
            ) {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zysy.jpg");
              buyList[i].Content = buyList[i].Content.replace(
                /一次通关/g,
                "助力备考!"
              );
            }
            if (buyList[i].VerName == "全程定制班") {
              buyList[i].Content = buyList[i].Content.replace(
                /一次通关/g,
                "考试顺利"
              );
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zysy.jpg");
            }
            if (buyList[i].VerName == "冲刺抢分班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zysy.jpg");
              buyList[i].ExtJson = "";
            }
          }
          //执业药师
          if (simpclass == "执业药师") {
            if (
              buyList[i].VerName == "精品题库班" ||
              buyList[i].VerName == "精讲课程班"
            ) {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zyys.jpg");
            }
            if (buyList[i].VerName == "全程提分班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zyys.jpg");
              buyList[i].Content = buyList[i].Content.replace(
                /一次通关/g,
                "助力备考!"
              );
            }
            if (buyList[i].VerName == "全程定制班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zyys.jpg");
              buyList[i].Content = buyList[i].Content.replace(
                /一次通关/g,
                "考试顺利"
              );
            }
            if (buyList[i].VerName == "私教协议班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zyys.jpg");
              buyList[i].ExtJson = "";
            }
            if (buyList[i].VerName == "VIP定制班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zyys.jpg");
              buyList[i].ExtJson = "";
            }
            if (buyList[i].VerName == "考点金题班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zyys.jpg");
              buyList[i].ExtJson = "";
            }
            if (buyList[i].VerName == "冲刺抢分班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zyys.jpg");
              buyList[i].ExtJson = "";
            }
          }
          //执业医师
          if (simpclass == "执业医师") {
            if (buyList[i].VerName == "精品题库班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zy_ys.jpg");
            }
            if (buyList[i].VerName == "精讲课程班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zy_ys.jpg");
            }
            if (buyList[i].VerName == "技能无忧班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zy_ys.jpg");
            }
            if (buyList[i].VerName == "全程提分班") {
             buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zy_ys.jpg");
              buyList[i].Content = buyList[i].Content.replace(
                /一次通关/g,
                "助力备考!"
              );
            }
            if (buyList[i].VerName == "全程定制班") {
              buyList[i].Content = buyList[i].Content.replace(
                /一次通关/g,
                "考试顺利"
              );
             buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zy_ys.jpg");
            }
            if (buyList[i].VerName == "实践技能无忧班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zy_ys.jpg");
            }
            if (buyList[i].VerName == "私教协议班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zy_ys.jpg");
              buyList[i].ExtJson = "";
            }
            if (buyList[i].VerName == "冲刺抢分班") {
             buyList[
                i
              ].ExtJson.imgurl = require("../assets/20221121/zy_ys.jpg");
            }
          }
          //护士执业
          if (simpclass == "执业护士") {
            if (
              buyList[i].VerName == "精品题库班" ||
              buyList[i].VerName == "精讲课程班" ||
              buyList[i].VerName == "冲刺抢分班" ||
              buyList[i].VerName == "全程提分班" ||
              buyList[i].VerName == "全程协议班" ||
              buyList[i].VerName == "全程定制班"
            ) {
              buyList[i].ExtJson.imgurl = require("../assets/20221121/cz.jpg");
            }
          }

          //医学学历 --医学博士外语考试
          if (buyList[i].AppCName == "医学博士外语考试") {
            if (buyList[i].VerName == "基础题库") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/career/ysbswy/jctk.jpg");
            }
          }
          //医疗招聘面试课 E类面试
          if (buyList[i].AppCName == "E类面试") {
            if (buyList[i].VerName == "私教协议班") {
              buyList[i].ExtJson = "";
            }
            // if(buyList[i].VerName == '全程协议班'){
            // 	buyList[i].ExtJson='';
            // }
            // if(buyList[i].VerName == '私教冲刺班'){
            // 	buyList[i].ExtJson='';
            // }
          }
          // 医疗招聘 公共课 公共基础知识
          //2022医学考研护理综合308
          if (buyList[i].AppCName == "考研护理综合") {
            if (
              buyList[i].VerName == "全程定制班" ||
              buyList[i].VerName == "全程提分班"
            ) {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/career/kyhlzh/jptkb_qctfb_qcdzb.jpg");
            }
          }
          //2022医学考研西医综合306
          if (buyList[i].AppCName == "考研西医综合") {
            if (
              buyList[i].VerName == "全程定制班" ||
              buyList[i].VerName == "全程提分班"
            ) {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/career/kyxyzh/jptkb_qctfb_qcdzb.jpg");
            }
          }
          if (buyList[i].AppCName == "考研西医综合") {
            if (
              buyList[i].VerName == "全程定制班" ||
              buyList[i].VerName == "全程提分班"
            ) {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/career/kyxyzh/jptkb_qctfb_qcdzb.jpg");
            }
          }

          if (buyList[i].AppCName == "生殖健康咨询师") {
            if (buyList[i].VerName == "基础题库") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/career/szjkzx/jptkb.jpg");
            }
          }
          if (simpclass == "卫生人才评价") {
            buyList[
              i
            ].ExtJson.imgurl = require("../assets/career/rcpj/jctk.jpg");
          }
          if (buyList[i].AppCName == "小儿推拿师") {
            buyList[
              i
            ].ExtJson.imgurl = require("../assets/career/xetn/qctxb.jpg");
          }
          if (simpclass == "医师定期考核") {
            buyList[
              i
            ].ExtJson.imgurl = require("../assets/career/ysdqkh/jctk.jpg");
          }
          if (simpclass == "医用设备考评") {
            buyList[
              i
            ].ExtJson.imgurl = require("../assets/career/yysbnl/jctk.jpg");
          }
          if (buyList[i].AppCName == "中医师承及确有专长考核") {
            buyList[
              i
            ].ExtJson.imgurl = require("../assets/career/zyscjqyzckh/jctk.jpg");
          }
          if (buyList[i].AppCName == "保育员高级") {
            buyList[
              i
            ].ExtJson.imgurl = require("../assets/career/byy/jctk.jpg");
          }
          if (buyList[i].AppCName == "产后康复师") {
            if (buyList[i].VerName == "全程特训班") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/career/chkfs/qctxb.jpg");
            }
          }
          if (buyList[i].AppCName == "养老护理员（高级）") {
            if (buyList[i].VerName == "基础题库") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/career/ylhlr/jctk.jpg");
            }
          }

          if (buyList[i].AppCName == "营养师") {
            if (buyList[i].VerName == "基础题库") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/career/yys/jctk.jpg");
            }
          }
          if (buyList[i].AppCName == "育婴师") {
            if (buyList[i].VerName == "精品题库") {
              buyList[
                i
              ].ExtJson.imgurl = require("../assets/career/yyis/jptk.jpg");
            }
          }
          if (
            buyList[i].VerName == "全程协议班" ||
            buyList[i].VerName == "私教协议班" ||
            buyList[i].VerName == "私教冲刺班" ||
            buyList[i].VerName == "急救包" ||
            buyList[i].VerName == "急救包plus" ||
            buyList[i].VerName == "急救包阅后即焚" ||
            buyList[i].VerName == "私教强化班" ||
            buyList[i].VerName == "冲刺抢分班" ||
            buyList[i].VerName == "提分加血包" ||
            buyList[i].VerName == "双师助力服务包" ||
            buyList[i].VerName == "湖南高级职称面审冲刺班" ||
            buyList[i].VerName == "卫生系统招聘考试" ||
            buyList[i].VerName == "面试协议A班" ||
            buyList[i].VerName == "面试协议B班" ||
            buyList[i].VerName == "面试集训营A班" ||
            buyList[i].VerName == "面试集训营B班" ||
            buyList[i].VerName == "面试集训营C班"
          ) {
            buyList[i].ExtJson = "";
          }

          // buyList.sort(function(a, b) {
          // 	return a.Price - b.Price
          // })

          // buyList.sort(function(a, b) {
          // 	return a.index - b.index
          // })
        }
        that.buyList = buyList;
      });
    },
    //观看视频
    onPlayVideo: function (item) {
      window.open(
        `http://hzstg.ksbao.com/freeclasspc/#/?slbs=100088-1&slbs2=&source=武汉神州PC&item=${JSON.stringify(
          item
        )}`
      );
    },
    onPlay(source) {
      that.player = new Aliplayer(
        {
          id: "J_prismPlayer",
          width: "700",
          height: "500px",
          autoplay: true,
          source: source,
        },
        function (player) {}
      );
    },
    onVideoOpen(bookID) {
      this.bookID = bookID;
      this.onVideoCatalogueSublevel(bookID, "on");
    },
    //考点精讲
    onVideoCatalogue(AppID) {
      let data = {
        appID: AppID,
        guid: JSON.parse(sessionStorage.getItem("loginData")).guid,
        type: 1,
        hierarchy: 0,
      };
      videoCatalogue(data).then((res) => {
        that.videoList = res.data.data;
        that.bookID = res.data.data[0].bookID; //默认第一个bookID
        that.onVideoCatalogueSublevel(res.data.data[0].bookID, "load");
      });
    },
    //考点精讲第二层
    onVideoCatalogueSublevel(bookID, behavior) {
      let data = {
        appID: this.AppID,
        guid: JSON.parse(sessionStorage.getItem("loginData")).guid,
        type: 1,
        hierarchy: 1,
        bookID: bookID,
      };
      videoCatalogue(data).then((res) => {
        that.videoSublevelList = res.data.data.childs;
        //打开模态了执行
        if (behavior == "load") {
          that.defaultActive = JSON.stringify(
            res.data.data.childs[0].names[0].id
          );
          that.onVideoPlayUrl(res.data.data.childs[0].names[0].id);
        }
      });
    },
    onVideoSelect: function (videoID) {
      that.player.dispose(); //销毁
      this.onVideoPlayUrl(videoID);
    },
    onVideoPlayUrl(videoID) {
      let data = {
        agentID: 889,
        appID: this.AppID,
        guid: JSON.parse(sessionStorage.getItem("loginData")).guid,
        videoID: videoID,
        src: 4,
        type: 1,
        bookID: this.bookID,
      };
      videoPlayUrl(data).then((res) => {
        that.onPlay(
          res.data.data.fileUrls[1].fileUrl +
            "?MtsHlsUriToken=" +
            res.data.data.token
        );
      });
    },
    //面包屑
    onCrumbs: function (e) {
      if (e == 1) {
        this.$message.info("当前页面班次详情页!");
        return;
      }
      this.$router.push({
        path: "/",
      });
    },

    tur() {},
    qrcodeScan() {
      //生成二维码
      var that = this;
      let qrcode = new QRCode("qrcode", {
        width: 125, // 二维码宽度
        height: 125, // 二维码高度
        text: that.wxUrl,
      });
    },
    zhezhao() {
      // qrcode.clear();
      console.log("我触发了");
      // this.reload();
    },
    //点击获取appid
    onSubjects: function (item) {
      this.AppID = item.AppID;
      sessionStorage.setItem("subjectChoose", JSON.stringify(item));
      this.$route.params.pathMatch = item.AppEName;
      this.pricelist();
      // window.open(window.location.host + "/details/" + item.AppEName)
      window.location.href =
        " http://" + window.location.host + "/details/" + item.AppEName;
    },
    callback(val) {},
    pricelist() {
      var that = this;
      that.kmLoadingShow = true;
      let data = {
        appID: this.AppID,
        agentCode: "100088-1",
        withPoint: 1,
        guid: JSON.parse(sessionStorage.getItem("loginData")).guid,
        groups: "[0,1,2,5]",
      };

      let urls = `http://116.62.79.97/mypro?url=http://deep-slb-pay.ksbao.com/api/pricelist?appID=${
        this.AppID
      }&agentCode=100088-1&withPoint=1&guid=${
        JSON.parse(sessionStorage.getItem("loginData")).guid
      }&groups=[0,1,2,5]`;
      this.axios({
        url: urls,
      }).then((res) => {
        if (res.data.status == 200) {
          var buyList = [];
          var list = res.data.data;
          for (let index = 0; index < list.length; index++) {
            if (
              list[index].VerName != "临床病例" &&
              list[index].VerName != "用药指南" &&
              list[index].VerName != "影像图鉴" &&
              list[index].VerName != "临床路径" &&
              list[index].VerName != "医学名词" &&
              list[index].VerName != "疾病知识" &&
              list[index].VerName != "诊断助手" &&
              list[index].BuyListHide == 0 &&
              list[index].Enable == 1
            ) {
              buyList.push(list[index]);
            }
          }
          if (buyList.length <= 5) {
            that.xsyx = false;
          } else {
            that.xsyx = true;
          }
          for (let i in buyList) {
            if (buyList[i].ExtJson && buyList[i].ExtJson != "{}") {
              buyList[i].ExtJson = JSON.parse(buyList[i].ExtJson);
            } else {
              buyList[i].ExtJson = "";
            }
          }
          that.onGetClass(buyList);
        }
        that.kmLoadingShow = false;
      });
      this.token = JSON.parse(sessionStorage.getItem("token"));
    },

    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.visible = false;
      this.reload();
    },
    dianjhq(val) {},
    wxPayment() {},
    //底部客服
    onCustomerService: function () {
      window.open(
        "http://p.qiao.baidu.com/cps/chat?siteId=6100319&userId=7778666"
      );
    },
  },
};
</script>

<style scoped lang="less">
.currently {
  font-size: 18px;
  color: #1890ff;
  padding: 20px;
  padding-bottom: 10px;
}
.classesdatasrm {
  padding: 20px;
  padding-top: 0;
}
.classesdatasrm span {
  border-left: 4px solid #1890ff;
  font-size: 16px;
  color: #333333;
  padding-left: 10px;
}
.currently span {
  color: #6e6e6e;
}
.contrast_b_bc {
  display: flex;
  padding-top: 10px;
}
.contrast_bcTitleBox {
  padding: 0 20px;
}
.contrast_bc_title {
  color: #62befc;
  padding-right: 10px;
}
.immediately {
  width: 228px;
  height: 38px;
  background: #fa8633;
  border-radius: 4px 4px 4px 4px;
  text-align: center;
  line-height: 38px;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  position: absolute;
  bottom: 20px;
  left: 18px;
  cursor: pointer;
}
.classesdatasVerName {
  padding: 0 20px;
  padding-top: 17px;
  height: 69px;
  font-size: 20px;
  color: #2d6ebb;
}
.astrict {
  display: flex;
  align-content: center;
}
.astrict_a {
  width: 60%;
  padding-left: 20px;
  color: #2d6ebb;
  font-weight: 500;
  font-size: 20px;
}
.astrict_b {
  width: 40%;
  padding-right: 20px;
  text-align: right;
  line-height: 30px;
}
.astrict_b span {
  width: 58px;
  height: 21px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #ffffff;
  font-size: 12px;
  color: #ffffff;
  padding: 2px 5px;
  cursor: pointer;
}
.classesdatas {
  display: flex;

  // justify-content: space-between;
  flex-wrap: wrap;
  padding: 23px;
  padding-right: 0;
  padding-top: 0px;
  // justify-content: space-around;
}
.classesdatasBOX {
  // height: 65vh;
  // overflow-y: auto;
}
.classesdatas_a {
  margin-bottom: 20px;
  width: 268px;
  height: 521px;
  margin-right: 24px;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #e1e5f3;
  position: relative;
  // margin: 10px 14px;
}
.classesdatas_b {
  height: 108px;
  background: linear-gradient(94deg, #daeaf7 0%, #99c2f2 100%);
  border-radius: 7px 7px 0px 0px;
}
.classesdatasImg {
  position: absolute;
  top: -40px;
  right: 0;
}
.classesdatasImg img {
  width: 46px;
  height: 62px;
}
.classesdatas_bs {
  height: 108px;
  background: linear-gradient(94deg, #feedd8 0%, #efaf85 100%);
  border-radius: 7px 7px 0px 0px;
  position: relative;
}
.example {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.KsbClassInfoTitle {
  border-bottom: 1px solid #eeeeee;
}

.footer-right {
  overflow: hidden;
  width: 345px;
  height: 180px;
  opacity: 1;
  background: #e3f6ff;
  cursor: pointer;
}

.footer-right-text {
  opacity: 1;
  font-size: 22px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #1d1d1d;
  margin-top: 24px;
  margin-left: 20px;
  letter-spacing: 2px;
}

.contact {
  display: flex;
  flex-direction: row;
}

.contact-left {
  width: 222px;
  height: 100%;
  margin-top: 25px;
}

.contact-left-li {
  color: #ffa91e;
  font-size: 20px;
  margin-left: 20px;
}

.contact-right {
  width: 122px;
  height: 100%;
  margin-top: 15px;
}

.contact-right img {
  width: 100%;
}

.el-menu-vertical-demo-box {
  overflow: scroll;
  height: 500px;
}

.el-menu-vertical-demo-box::-webkit-scrollbar {
  display: none;
}

.video-box {
  height: 500px;
}

.crumbs-text {
  width: 1200px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.lewu {
  text-align: center;
  color: #bfbfbf;
}

.tubiaoya {
  font-size: 40px;
  margin-top: 150px;
  color: #bfbfbf;
}

.zzzf {
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 11px;
  left: 10px;
  cursor: pointer;
}
.zzzfs {
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  top: 14px;
  left: 45%;
}

.tcbox {
  margin: 30px 15px;
  margin-bottom: 100px;
}

.jine {
  font-size: 28px;
}

.imgsewm {
  text-align: center;
  // .zfb{
  // 	// padding-top: 50px;
  // 	margin-top: 30px;
  // }

  span {
    display: inline-block;
    margin: 0 70px;
  }
}

.zhifubao {
  cursor: pointer;
  text-align: center;
  margin: 15px 10px;

  width: 120px;
  height: 25px;
  line-height: 25px;
  background-color: #2caeff;
  color: #fff;
  border-radius: 4px;
}

.zhifubao:hover {
  background-color: #0d9bf2;
}

.yfje {
  text-align: center;
  margin-bottom: 30px;
}

.fuwu {
  padding: 11px 0px;
  font-size: 16px;
  margin-right: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.fuwu-box {
}

.fuwu-text {
  padding: 2px 10px;
  opacity: 1;
  background: #fff4de;
  color: #666a6f;
  font-size: 14px;
  font-weight: 300;
  padding-left: 10px;
  margin-left: 10px;
  box-sizing: border-box;
}

.fuwu-text + .fuwu-text {
  margin-left: 10px;
}

.boxs/deep/.ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 50px;
  height: 0px;
  background-color: #1890ff;
  /* transform-origin: 0 0; */
}

.boxs/deep/.ant-tabs-bar {
  margin: 16px 1px 16px 1px;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  // border: 1px solid #ccc;
  height: 55px;
  padding-top: 4px;
  box-shadow: 0px 0px 10px #ccc;
}

.boxs/deep/ .ant-tabs-tab-prev-icon-target,
.boxs/deep/.ant-tabs-tab-next-icon-target {
  width: 45px;
  // font-size: 50px;
  color: #62befc;
  // padding:30px 20px;
  margin: 30px 20px;
}

.ant-tabs-tab-prev-icon {
  // background: #62befc;
}

.boxs/deep/.ant-tabs-tab-prev.ant-tabs-tab-arrow-show {
  // background: #62befc;
}

.tbia {
  position: relative;
  left: 0px;
  top: 71px;
  font-size: 20px;
  // z-index: 1111;
  padding: 18px 15px;
  background: #62befc;
  color: #fff;
}

.tbiarig {
  position: relative;
  right: -1100px;
  top: 71px;
  font-size: 20px;
  // z-index: 1111;
  padding: 18px 15px;
  background: #62befc;
  color: #fff;
}

.boxs/deep/.ant-tabs-tab-active {
  background: #62befc;
  border-radius: 15px;
  color: #fff;
}

.boxs/deep/.ant-tabs-nav-container-scrolling {
  padding-right: 70px;
  padding-left: 70px;
}

// .boxs/deep/.ant-tabs-nav-container{
//  margin-left: 40px;
// }
.boxs/deep/.ant-tabs-nav .ant-tabs-tab {
  width: 185px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  padding: 14px 16px;
  text-decoration: none;
  cursor: pointer;
  // background-color: #ccc;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
}

.boxs .public {
  width: 100%;
}

.details {
  // height: 900px;
  background-color: #fff;
}

.myde {
  height: 900px;
  background-color: #ccc;
}

.typepage {
  width: 1200px;
  background-color: #fff;
  margin: 0 auto;
  // overflow: hidden;
  // background-color: #ea4335;
}

.left {
  width: 784px;
  // height: 900px;
  float: left;
}

.right {
  // background-color: red;
  width: 370px;
  // height: 900px;
  float: right;
  margin-left: 43px;
}

.banner-a {
  width: 750px;
  height: 350px;
  margin-left: 17px;
  margin-top: 18px;
}

.banner-dd {
  width: 750px;
  // height: 20px;
  margin-left: 17px;
  margin-top: 18px;
}

.banner-a img {
  width: 750px;
  height: 350px;
}

.buyte {
  margin-top: 15px;
}

.banner-b {
  width: 784px;
  // background-color: #00f7de;
  margin-top: 42px;
}

.banci {
  width: 390px;
  margin-top: -21px;
  // opacity: 0.7;
  // overflow: hidden;
}

.xshiimg {
  width: 100%;
  // margin-top: -100px;
}

.title {
  width: 390px;
  // height: 20px;
  font-size: 20px;
  padding-top: -20px;

  font-family: Source Han Sans CN Regular;
  text-align: center;
  margin-top: 22px;
  font-weight: 400;
  color: #333333;
}

.line-a {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 10px;
}

.line-b {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 16px;
}

.banci ul {
  list-style-type: none;
  padding: 0;
  margin-left: 20px;
}

.banci ul li {
  margin-top: 21px;
  // float: left;
}

.banci-a {
  color: #333333;
  width: 149px;
  height: 16px;
  font-weight: Normal;
  font-family: Source Han Sans CN Regular;
  font-size: 16px;
}

.banci-b {
  width: 149px;
  color: #333333;
  height: 14px;
  font-size: 14px;
}

.banci-c {
  width: 350px;
  font-size: 14px;
  margin: 5px;
}

.banci-d {
  width: 380px;
  height: 14px;
  font-size: 14px;
}

.banci-c-img {
  float: left;
  margin-top: 3px;
}

.banci-c-text {
  float: left;
  width: 80px;
  height: 20px;
  color: #666a6f;
  margin-right: 15px;
  background: #fff4de;
  margin: 5px;
}

.banci-d-img {
  float: left;
  margin-top: 3px;
}

.banci-d-text {
  float: left;
  color: #666a6f;
}

.price {
  font-size: 32px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
  text-align: center;
  color: #ea4335;
  margin-left: 20px;
}

.aa {
  width: 390px;
  height: 304px;
  margin-top: 60px;
  // background-color: #01AAED;
}

.aa-title {
  font-size: 21px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  color: #333333;
  margin-left: 19px;
}

.aa-q {
  width: 390px;
  // height: 60px;
  float: left;
  // margin-bottom: 17px;
}

.aa-q img {
  float: left;
  margin-top: 8px;
  margin-left: 19px;
}

.aa-q ul {
  width: 262px;
  padding: 0;
  margin-left: 21px;
  list-style-type: none;
  float: left;
}

.aa-w {
  font-size: 15px;
  font-weight: Normal;
  margin-top: 9px;
  color: #333333;
}

.aa-e {
  font-size: 13px;
  color: #666a6f;
}

.ljgmyas-right {
  display: flex;
  justify-content: space-around;
  margin-top: 31px;
}

.watchVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 156px;
  height: 40px;
  opacity: 1;
  color: #ffffff;
  background: #62befc;
  border-radius: 5px;
  cursor: pointer;
}

.ljgmya {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 156px;
  height: 40px;
  opacity: 1;
  color: #ffffff;
  background: #e85a34;
  border-radius: 5px;
  cursor: pointer;
}

.ljgmya:hover {
  opacity: 0.9;
}

.ljgmyas {
  display: inline-block;

  // background: #e85a34;
  font-size: 32px;
  color: #e85a34;
  margin-top: 31px;
  // line-height: 42px;
}

.titlename {
  text-align: center;
  margin-top: 70px;
  font-size: 21px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #333333;
}

.namedandu {
  padding-bottom: 0px;
  font-size: 16px;
}

.yxtime {
  margin-right: 10px;
  margin-left: 2px;
}

.ygmai {
  border-radius: 10px;
  background-color: #ccc;
}

.ygmai:hover {
  cursor: no-drop;
}

.zfb {
  margin: 0px 50px !important;
}

.qqq {
  width: 120px;
  height: 120px;
}

.footer-right-text-span {
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #8c8c8c;
}

.qrCode_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 30px;
  width: 346px;
  height: 160px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  margin-bottom: 30px;
  position: relative;
}

.qrCode_box_img {
  display: flex;
  width: 142px;
  height: 142px;
  opacity: 1;
}

.qrCode_box_ul {
  color: #8c8c8c;
}

.clickDownload {
  width: 20px;
  position: absolute;
  right: 10px;
  top: 30px;
  line-height: 20px;
  cursor: pointer;
  color: #62befc;
}

// #qrcode{
// 	padding-top: 30px;
// }

.typepage .contrast/deep/.ant-modal-body {
  padding: 0px !important;
  height: 70vh;
  overflow-y: auto;
}
.typepage .contrasts/deep/.ant-modal-body {
  padding: 0px !important;
  overflow-y: auto;
}
.group-text {
  position: absolute;
  left: 275px;
  top: 5px;
  color: #ff7173;
}

.checkboxGroup-box {
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid #eeeeee;
}

.borede {
  border: none;
}

.group-box {
  width: 60%;
  margin: 0 auto;
}

.checkboxGroup-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.checkboxGroup {
  width: 100%;
  height: 40px;
  position: relative;
}

.checkboxGroup-box/deep/.el-checkbox__inner {
  width: 20px !important;
  height: 20px !important;
  border-radius: 35px;
}

.checkboxGroup-box/deep/.el-checkbox__inner::after {
  height: 10px;
  left: 7px;
}

.group-flex/deep/.el-checkbox__inner::before {
  background: none !important;
}

.group-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.buy-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background: #1890ff;
  position: relative;
  left: 150px;
  cursor: pointer;
}

.checkbox-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SubPrice {
  text-decoration: line-through;
  font-size: 15px;
  color: #cccccc;
}

.totalPrices {
  color: #ff7173;
  font-size: 18px;
  margin-left: 20px;
}

.typepage .contrast/deep/.ant-modal-body::-webkit-scrollbar {
  /* 设置竖向滚动条的宽度 */
  width: 8px;
  /* 设置横向滚动条的高度 */
  height: 16px;
  cursor: pointer;
}

.typepage .contrast/deep/.ant-modal-body::-webkit-scrollbar-thumb {
  /*滚动条的背景色*/
  background-color: rgba(144, 147, 153, 0.3);
  border-radius: 30px;
  position: relative;
  cursor: pointer;
}
.zfvn {
  font-size: 18px;
  margin-top: 50px;
  border-top: 1px solid #ccd;
  padding: 0 40px;
  padding-top: 24px;
}
.zfvn span {
  font-weight: 500;
}
.fgcls {
  width: 100%;
  border-top: 1px solid #ccd;
}
.checkboxGroupBox {
  padding: 22px;
}
</style>
