<template>
	<div class="wrap">
		<div class="el-container" id="components-popover-demo-placement">
			<div class="customer">
				<img src="../assets/img/consult.png" @click="lianxikefu" />
			</div>
			<a-popover placement="left">
				<template slot="content">
					<p style="text-align: center;">4001856601</p>
					<p style="text-align: center;">服务时间：8:30-22:00</p>
				</template>
				<div class="" @mouseenter="onNmouseover" @mouseleave="onMouseleave">
				<img  v-show="!mouseenterShow" src="../assets/img/phone.png" style="margin-top: 20px;cursor: pointer;">
				<div v-show="mouseenterShow"  @mouseleave="onMouseleave" class="Service-bgs">
					<span class="Service-span">客服</span>
					<span class="Service-span">热线</span>
				</div>
				</div>
			</a-popover>
			<!-- QQ end -->
			<!-- 扫一扫关注公众号 -->
			<a-popover placement="left">
				<template slot="content">
					<img src="../assets/contactQrCode.png" alt="" class="down-img" />
					<p style="text-align: center;">微信客服</p>
					<p style="text-align: center;">服务时间：8:30-22:00</p>
				</template>
				<div class="111" @mouseenter="onNmouseoverWeChat" @mouseleave="onMouseleaveWeChat">
				<img  v-show="!mouseenterWeChatShow" src="../assets/img/qrCode.png" style="margin-top: 20px;cursor: pointer;">
				<div v-show="mouseenterWeChatShow"  @mouseleave="onMouseleave" class="Service-bgs">
					<span class="Service-span">关注</span>
					<span class="Service-span">微信</span>
				</div>
				</div>
			</a-popover>
			<!-- 扫一扫关注公众号 end -->
			<!-- 客服 -->
			<!-- 
				<div class="el-aside" >
					<div class="el-aside">
						<div class="text">咨询热线</div>
					</div>
				</div>
				<div class="text">400-727-8800</div> -->

			<!-- 客服 end -->

			<!--  -->

		</div>

		<!-- <div class="el-containera" id="components-popover-demo-placementa" v-if="btnFlag"  @click="goPost">
		<div class="el-aside">
			<div class="el-row el-rows">
				<img src="../assets/dingbu.png">
			</div>
			
		</div>
		<div class="text">回到顶部</div>
		</div> -->

	</div>
</template>


<script>
	var that;
	export default {
		components: {},
		data() {
			return {
				buttonWidth: 70,
				//v-if为true显示，false消失
				btnFlag: false,
				//记录屏幕滑动的值
				scrollTop: 0,
				mouseenterShow: false ,
				mouseenterWeChatShow: false ,
			};
		},
		mounted() {
			that = this;
			window.addEventListener('scroll', this.scrollToTop);
		},

		destroyed: function() {
			window.removeEventListener('scroll', this.scrollToTop)
		},
		methods: {
			//悬停
			onNmouseover: function() {
				that.mouseenterShow = true
			},
			//移开
			onMouseleave: function() {
				that.mouseenterShow = false
			},
			//悬停
			onNmouseoverWeChat: function() {
				that.mouseenterWeChatShow = true
			},
			//移开
			onMouseleaveWeChat: function() {
				that.mouseenterWeChatShow = false
			},
			
			lianxikefu() {
				window.open("http://p.qiao.baidu.com/cps/chat?siteId=6100319&userId=7778666");
				// location.href='http://p.qiao.baidu.com/cps/chat?siteId=6100319&userId=7778666'
			},

			// 点击回到顶部
			goPost() {
				// let distance =
				//   document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
				// let step = distance / 30; //每步的距离
				// (function jump() {
				//   if (distance > 0) {
				//     distance -= step;
				//     window.scrollTo(0, distance);
				//     setTimeout(jump, 10);
				//   }
				// })();
				const that = this;
				let timer = setInterval(() => {
					let ispeed = Math.floor(-that.scrollTop / 5)
					document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
					if (that.scrollTop === 0) {
						clearInterval(timer)
					}
				}, 16)
			},
			scrollToTop() {
				const that = this
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				that.scrollTop = scrollTop
				if (that.scrollTop > 60) {
					that.btnFlag = true
				} else {
					that.btnFlag = false
				}
			}
		}
	};
</script>

<style scoped>
	.wrap {
		width: 90px;
		height: 600px;
		border-radius: 10px;
		/* border: 2px solid #d3dce6; */
		position: fixed;
		right: 5px;
		top: 60%;
		z-index: 9999;


	}

	.customer {
		width: 68px;
		height: 68px;
		opacity: 1;
		background: #def2ff;
		cursor: pointer;
		border-radius: 5px;
	}

	#components-popover-demo-placement .ant-btn {
		width: 80px;
		text-align: center;
		padding: 0;
		margin-right: 8px;
		margin-bottom: 8px;
	}

	#components-popover-demo-placementa .ant-btn {
		width: 70px;
		text-align: center;
		padding: 0;
		margin-right: 2px;
		margin-bottom: 2px;
	}

	/*  */
	.el-container {
		right: 5px;
		/* top: 25%; */
		z-index: 9999;
	}

	.el-containera {
		width: 90px;
		height: 80px;
		border-radius: 10px;
		/* border: 2px solid #d3dce6; */
		/* position: fixed; */
		right: 5px;
		margin-top: 15px;
		z-index: 9999;
		box-shadow: 0px 0px 10px 1px #63caff;
		background: #2cb5fc;
	}

	.el-aside {
		display: flex;
		align-items: center;
		justify-content: center;
		/* margin-bottom: 2px; */
		/* width: 50px; */
		height: 60px;
		width: 90px;
		/* background: #007400; */
	}

	.el-aside img {
		/* width: 40px;
  height: 40px; */

	}

	.line {
		width: 70px;
		height: 1px;
		margin-left: 9px;
		background-color: #fff;
		margin-top: 5px;
	}

	.el-row {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border-radius: 40px;
		box-sizing: border-box;
	}

	.el-rows:hover {
		/* background: #d3dce6; */
		cursor: pointer;
	}

	.text {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		margin-top: -10px;
		color: #fff;
	}

	.el-button--default {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		height: 110px;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 2px solid #DCDFE6;
		/* border-radius: 0px 0px 50px 50px; */
	}

	.is-circle {

		width: 50px;
		height: 50px;

	}

	.is-circle img {
		width: 30px;
		height: 30px;
	}

	.circles:hover {
		cursor: pointer;
	}

	.down-img {
		width: 120px;
		height: 120px;
	}

	.el-container {
		display: block;
	}
	.WeChat-bg{
		width: 100px;
		height: 100px;
	}
	.Service-bgs{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 68px;
		height:  68px;;
		opacity: 1;
		background: #59bdff;
		border-radius: 5px;
		margin-top: 20px;
		color: #FFFFFF;
		cursor: pointer;
	}
	.Service-span{
		display: block;
	}
</style>
