import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// 引入公共样式
import '../src/style/common.less'
// 导入复制链接插件
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard);
import './icons' //  全局注册svg组件
// import IconSvg from '@/components/IconSvg'
// //全局注册icon-svg
// Vue.component('icon-svg', IconSvg)
import onfreeFegist from './mode/freeFegist.js'

import onsubjectData from './mode/subjectData.js' //热门搜索数据


import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)
import axios from 'axios';
Vue.prototype.axios = axios;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import getMAC from 'getmac'

import dayjs from 'dayjs';
Vue.prototype.dayjs = dayjs;

Vue.prototype.getFreeFegist = onfreeFegist.getFreeFegist; //免费试用
Vue.prototype.$subjectData = onsubjectData.subjectData; //免费试用

//引用qs
import qs from 'qs';
Vue.prototype.qs = qs;
Vue.config.productionTip = false
    //引入MD5
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
//导入cookie
import cookie from "js-cookie"
//将cookie转换为 vue 原型
Vue.prototype.Cook = cookie


// 初始化神策SDK============================start
var sensors = require('sa-sdk-javascript');
sensors.init({
    //埋点的数据上传接口
    server_url: 'http://121.40.46.44:7001/api/GetCustomizedClick?',
    is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
    use_client_time: true,
    get_vtrack_config: true,
    send_type: 'GET',
    show_log: false, //控制台显示打印日志
    header: 'Content-Type: application/json',
    heatmap: {
        //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
        clickmap: 'not_collect',
        //是否开启触达注意力图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
        scroll_notice_map: 'not_collect',
        //需要采集的标签
        collect_tags: {
            div: {
                max_level: 3 // 默认是 1，即只支持叶子 div。可配置范围是 [1, 2, 3],非该范围配置值，会被当作 1 处理。
            },
            li: true,
            span: true,
            img: true
        },

        //不需要采集的标签
        //此参数针对预置 $WebClick 事件（包括 quick('trackHeatMap') quick('trackAllHeatMap') 触发的）生效。
        collect_element: function(element_target) {
            // 如果这个元素有属性sensors-disable=true时候，不采集。
            if (element_target.getAttribute('bucaiji') === 'true') {
                return false;
            } else {
                return true;
            }
        },

        //採集到的標籤，通過判斷重新設置名字
        //此参数针对预置 $WebClick 事件（包括 quick('trackHeatMap') quick('trackAllHeatMap') 触发的）生效。
        custom_property: function(element_target) {
            //比如您需要给有 data=test 属性的标签的点击事件增加自定义属性 name:'aa' ，则代码如下：
            var element_name = '';
            var location = '';
            var obj = {};
            if (element_target.getAttribute('resetname') === 'true') {
                obj.element_name = element_target.getAttribute('newname')
            }
            if (element_target.getAttribute('resetlocation') === 'true') {
                obj.location = element_target.getAttribute('newlocation')
            }
            if (element_target.getAttribute('resetvideoid') === 'true') {
                obj.videoid = element_target.getAttribute('newvideoid')
            }

            return obj;
        },
    }
});

//已登錄

//默认返回0 初始化埋點信息
// var AgentInfo = JSON.parse(sessionStorage.getItem("AgentInfo"));
//已登錄
var u = navigator.userAgent;
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //如果输出结果是true就判定是android终端或者uc浏览器

var os = "未知";
if (isAndroid) {
    os = "安卓"
} else {
    os = "苹果"
}

var borwser = "未知";
var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
if (userAgent.indexOf("Opera") > -1) { //判断是否Opera浏览器
    borwser = "Opera"
};
if (userAgent.indexOf("Firefox") > -1) { //判断是否Firefox浏览器
    borwser = "FF";
}
if (userAgent.indexOf("Chrome") > -1) { //判断是否Chrome浏览器
    borwser = "Chrome";
}
if (userAgent.indexOf("Safari") > -1) { //判断是否Safari浏览器
    borwser = "Safari";
}
if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) { //判断是否IE浏览器
    borwser = "IE";
};


sensors.registerPage({
    agentCode: "100088-1",
    clientType: 'h5',
    os: os,
    borwser: borwser,
    source: '武汉神州网校版PC端'
});

sensors.quick('autoTrack');





Vue.prototype.$sensors = sensors //挂载神策
    // 初始化神策SDK============================end


import $ from 'jquery'
// let interfaces=require('os').networkInterfaces();
// console.log("mac=====>"+JSON.stringify(interfaces))
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')