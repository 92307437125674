<template>
  <div class="wrap">
    <div class="modal" v-show="showSubject" @click="onSelect"></div>
    <div class="example" v-show="kmLoadingShow">
      <a-spin />
    </div>
    <!-- 分类 -->
    <div class="subject_groupBy">
      <div class="subject_groupBy-list">
        <div class="subject_left" style="margin-top: 3px">
          <div class="subject_title">分类:</div>
          <!-- <div class="subject_all">全部</div> -->
        </div>
        <div
          class="subject_left all"
          v-show="showAllBtn"
          :class="MediClassID == 3 ? 'allActive' : ''"
          @click="onAllBtnA"
        >
          <div class="subject_titleS">全部</div>
          <!-- <div class="subject_all">全部</div> -->
        </div>
        <ul class="subject_ul">
          <li
            v-for="(medataItem, medataIndex) in medata"
            :key="medataIndex"
            @click="onMedata(medataItem.MediClassID)"
            style="margin-top: 3px"
            v-show="medataItem.MediClassID != 0"
          >
            <div
              class="subject_list-title"
              :class="
                medataItem.MediClassID == MediClassID ? 'active' : 'underline'
              "
            >
              {{ medataItem.Title }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 系列 -->
    <div class="subject_groupBy" style="margin-top: 30px">
      <div class="subject_groupBy-list">
        <div class="subject_left">
          <div class="subject_title">系列:</div>
          <!-- <div class="subject_all">全部</div> -->
        </div>
        <ul class="subject_ul">
          <li
            v-for="(item, index) in list"
            :key="index"
            v-if="item.MediClassID == MediClassID"
            @click="onSeries(item.KsbClassID, item)"
          >
            <div
              class="s·e"
              :class="item.KsbClassID == KsbClassID ? 'active' : 'underline'"
            >
              {{ item.KsbClassName }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div class="SubjectToChoose">
      <div class="subject_title">科目:</div>
      <div class="Select">
        <div style="display: flex; flex-direction: row">
          <div
            class="Select-input"
            @mouseover="mouseOver"
            @mouseleave="mouseLeave"
          >
            <div class="Select-input-left" @click="onClickInput">
              <!-- {{infoName}} -->
              <a-input
                @change="onChange"
                v-model="appPName"
                style="width: 223px; height: 25px; border: none"
                placeholder="选择或输入科目名称"
              />
            </div>
            <div
              class="Select-input-right"
              @click="onSelect"
              v-show="!closeTrue"
            >
              <a-icon type="up" v-show="showSubject" />
              <a-icon type="down" v-show="!showSubject" />
            </div>
            <a-icon
              type="close-circle"
              class="close-circle"
              v-show="closeTrue"
              @click="onClose"
            />
          </div>
          <span class="msg" v-show="appPName == ''">
            <a-icon type="double-left" />
            请查找或输入具体科目
          </span>
        </div>
        <!-- 动画闪动 -->
        <div class="super-input" v-show="appPName == ''">
          <div class="rozbo"></div>
        </div>
        <!-- 动画闪动 end-->
        <div class="Select-box" v-show="showSubject && !kc">
          <div class="triangle_border_up">
            <span></span>
          </div>
          <div class="Select-box-bottom">
            <div class="Select-box_getKsbClass" v-show="Childs != 2">
              <div
                class="Select-box-list"
                :class="ksbClassItem.KsbClassID == KsbClassID2 ? 'active2' : ''"
                v-for="(ksbClassItem, ksbClassIndex) in KsbClass"
                :key="ksbClassIndex"
                @click="onKsbClass(ksbClassItem.KsbClassID)"
              >
                <div class="Select-box-list-left">
                  {{ ksbClassItem.KsbClassName }}
                </div>
                <a-icon type="right" />
              </div>
            </div>
            <div
              class="Select-box_KsbClassInfo"
              v-show="Childs == 2 || triggerLayer2"
            >
              <div
                class="Select-box-list"
                v-for="(infoItem, infoIndex) in KsbClassInfo"
                :key="infoIndex"
                @click="onKsbClassInfo(infoItem)"
              >
                {{
                  infoItem.Name.lastIndexOf("［") == -1
                    ? infoItem.Name
                    : infoItem.Name.substr(0, infoItem.Name.lastIndexOf("［"))
                }}
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <div class="Select-box" v-show="kc">
          <div class="triangle_border_up">
            <span></span>
          </div>
          <div class="Select-box-bottom">
            <div class="Select-box_KsbClassInfo">
              <div
                class="Select-box-list"
                v-for="(searchDataItem, searchDataIndex) in searchData"
                :key="searchDataIndex"
                v-show="searchDataItem.Name.search(appPNameSearch) != -1"
                @click="onKsbClassInfo(searchDataItem)"
              >
                {{
                  searchDataItem.Name.lastIndexOf("［") == -1
                    ? searchDataItem.Name
                    : searchDataItem.Name.substr(
                        0,
                        searchDataItem.Name.lastIndexOf("［")
                      )
                }}
              </div>
              <div class="timg-box" v-show="!dataSearchTrue">
                <img src="../assets/timg.jpg" class="timg" />
                <div class="timg-text">没搜到该科目</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var that;
import { getKsbClass, getKsbClassInfo, getSearch } from "../api/api.js";
export default {
  props: {
    subjectData: {
      type: Object,
      default() {
        return {};
      },
    },
    showAllBtn: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      medata: [], //分类
      list: [], //系列
      MediClassID: 1, //1医学职业 2医学实用技术 0其他职业
      KsbClassID: 0, //默认选中正高卫生职称--临床医学类父级
      KsbClassID2: 180, //默认正高卫生职称
      KsbClassInfo: [], //科目
      KsbClass: [], //第二层
      Childs: 3,
      triggerLayer2: false, //点击第二层数据触发
      showSubject: false,
      appPName: "", //搜索的关键字
      closeTrue: false,
      AppID: 1682,
      appPNameSearch: "",
      dataSearchTrue: true, //是否搜到科目
      ChildsBox: [],
      searchData: [], //选中系列所有科目
      kc: false, //搜索科目
      showBtnA: false,
      matching: [
        "硕士研究生",
        "建筑工程",
        "消防工程",
        "财会类",
        "经济类",
        "事业单位招聘",
        "事业单位招聘",
        "保险",
        "教师考试",
        "警法考试",
        "司法考试",
        "社会工作者",
        "成人教育",
        "心理咨询师",
        "金融类",
        "出版资格",
        "国家招聘",
        "国家公务员",
      ],
      kmLoadingShow: false,
    };
  },
  mounted() {
    that = this;
    that.getKsbClass();
    // subjectCache
    if (JSON.stringify(that.subjectData) != "{}") {
      that.KsbClassID = that.subjectData.KsbClassID; //默认选中正高卫生职称--临床医学类父级
      // that.AppID = that.subjectData.AppID;
      that.MediClassID = that.subjectData.MediClassID; //1医学职业 2医学实用技术 0其他职业
      that.KsbClassID2 = that.subjectData.KsbClassID2; //默认正高卫生职称
      that.KsbClass = that.subjectData.KsbClass; //第二层
      that.KsbClassInfo = that.subjectData.KsbClassInfo; //科目
      that.Childs = that.subjectData.Childs;
      that.appPName = that.subjectData.appPName;

      //对医疗招聘进行特殊处理
      if (that.subjectData.MediClassID == 4) {
        that.KsbClassID = that.subjectData.KsbClassID2;
      }
      console.log(" that.KsbClassID", that.subjectData);
    }
  },
  methods: {
    //onAllBtnA
    onAllBtnA: function () {
      that.MediClassID = 3; //全部
      that.KsbClassID = null; //取消默认值
      this.$emit("allBtn");
    },
    onSubjectCache() {
      let data = {
        AppID: that.AppID,
        KsbClass: that.KsbClass,
        MediClassID: that.MediClassID, //1医学职业 2医学实用技术 0其他职业
        KsbClassID: that.KsbClassID, //默认选中正高卫生职称--临床医学类父级
        KsbClassID2: that.KsbClassID2, //默认正高卫生职称
        KsbClass: that.KsbClass, //第二层
        KsbClassInfo: that.KsbClassInfo, //科目
        Childs: that.Childs,
        appPName: that.appPName,
      };
      sessionStorage.setItem("subjectCache", JSON.stringify(data));
    },
    onClose: function () {
      (that.appPName = ""), (that.showSubject = false);
      that.kc = false;
    },
    mouseOver: function () {
      if (that.appPName != "") {
        that.closeTrue = true;
      }
    },
    mouseLeave: function () {
      that.closeTrue = false;
    },
    onClickInput: function () {
      that.showSubject = true;
    },
    onChange: function () {
      that.kc = true;
      // that.showSubject = false;
      // that.KsbClassID = 0; //不选中
      // that.KsbClassID2 = 0; //默认值
      that.appPNameSearch = that.appPName;
      let searchData = that.searchData;
      var dataSearch = new Array();
      for (let i in searchData) {
        // console.log('KsbClassInfo[i].Name.search(that.appPName)',KsbClassInfo[i].Name.search(that.appPName));
        if (searchData[i].Name.indexOf(that.appPName) != -1) {
          dataSearch.push(searchData[i].Name);
        }
        if (dataSearch.length != 0) {
          that.dataSearchTrue = true;
        } else {
          that.dataSearchTrue = false;
        }
      }
      if (that.appPName == "") {
        that.kc = false;
      }

      // let data = {
      // 	appPName: that.appPName,
      // };
      // getSearch(data).then((res) => {
      // 	if (that.appPName != "") {
      // 		that.showSubject = true;
      // 	} else {
      // 		that.showSubject = false;
      // 	}
      // 	if (res.data.status == 200) {
      // 		if (res.data.data.length != 0) {
      // 			that.KsbClassInfo = res.data.data; //可科目
      // 		} else {
      // 			that.KsbClassInfo = res.data.data; //可科目
      // 		}
      // 	}
      // });
    },
    //搜索
    onSearch: function () {
      that.KsbClassID = 0; //不选中
      that.KsbClassID2 = 0; //默认值
      that.Childs = 2; //2层数据

      let data = {
        appPName: that.appPName,
      };
      getSearch(data).then((res) => {
        if (that.appPName != "") {
          that.showSubject = true;
        }
        // if (res.data.status == 200) {
        //   if (res.data.data.length != 0) {
        //     that.KsbClassInfo = res.data.data; //可科目
        //   } else {
        //     that.KsbClassInfo = res.data.data; //可科目
        //   }
        // }
      });
    },
    onSelect: function () {
      that.showSubject = !that.showSubject;
      that.kc = false;
    },
    //选择分类
    onMedata: function (MediClassID) {
      that.MediClassID = MediClassID;
    },
    //选择系列
    onSeries: function (KsbClassID, item) {
      that.showSubject = true;
      //初始化
      that.Childs = 0;
      that.triggerLayer2 = false;

      that.KsbClassID = KsbClassID;
      if (item.Childs == null) {
        that.getKsbClassInfo(KsbClassID);
        that.onDataSearchBox(KsbClassID, "getKsbClassInfo");
        that.Childs = 2; //2层数据
      } else {
        that.KsbClass = item.Childs;
        that.ChildsBox = item.Childs; //搜索需要数据
        that.Childs = 3; //3层数据
        that.onDataSearchBox(that.KsbClass, "getKsbClass");
        // that.active = 'w' //还有第二层，不给默认值
      }
      that.appPName = "";
      that.$emit("Series");
      that.$parent.$parent.shuru = "";
    },
    //三层数据第二层
    onKsbClass: function (KsbClassID) {
      that.getKsbClassInfo(KsbClassID);
      that.KsbClassID2 = KsbClassID;
      that.triggerLayer2 = true;
    },
    //科目
    onKsbClassInfo: function (infoItem) {
      // 选择科目----*************

      //【判断infoItem‘》’前面字符串和‘［’后面字符串去掉
      if (
        infoItem.Name.substr(infoItem.Name.indexOf("》")) == -1 &&
        infoItem.Name.lastIndexOf("［") == -1
      ) {
        that.appPName = infoItem.Name; //不满足上面条件直接返回科目
      } else {
        let name = infoItem.Name.substr(infoItem.Name.indexOf("》") + 1); //先获取'》'后面字符串；
        if (name.lastIndexOf("［") == -1) {
          //获取'》'后面字符串，进行判断，‘［’是否存在，存在只截取前面字符串
          that.appPName = name; //不满足
        } else {
          that.appPName = name.substr(0, name.lastIndexOf("［")); //满足条件，再进行截取处理，并返回科目
        }
      }

      that.AppID = infoItem.AppID;
      //that.KsbClassID 系列id   MediClassID分类
      this.$emit("subjects", infoItem, that.KsbClassID, that.MediClassID);
      that.showSubject = false;
      that.kc = false;
      that.onSubjectCache();
    },
    getKsbClass() {
      //判断有resltu缓存不再执行下面请求
      if (JSON.parse(sessionStorage.getItem("resltu"))) {
        let resltu = JSON.parse(sessionStorage.getItem("resltu"));
        that.list = resltu.datas; //可科目
        that.medata = resltu.medata;

        that.onDataSearchBox(resltu.datas[0].Childs, "getKsbClass");
        that.triggerLayer2 = true;
        if (JSON.stringify(that.subjectData) == "{}") {
          //默认选中
          that.KsbClass = resltu.datas[0].Childs;
          that.getKsbClassInfo(that.KsbClassID2);
        }
        return;
      }

      that.kmLoadingShow = true;
      let data = {
        agentID: "100088-1",
        isNewMedi: 1,
      };
      getKsbClass(data).then((res) => {
        if (res.data.status == 200) {
          that.list = res.data.data; //可科目

          that.medata = res.data.medata;
          that.onDataSearchBox(res.data.data[0].Childs, "getKsbClass");
          that.triggerLayer2 = true;
          if (JSON.stringify(that.subjectData) == "{}") {
            //默认选中
            that.KsbClass = res.data.data[0].Childs;
            that.getKsbClassInfo(that.KsbClassID2);
          }
        } else {
          return false;
        }
        that.kmLoadingShow = false;
      });
      if (JSON.parse(sessionStorage.getItem("subjectCache")) == null) {
        that.onSubjectCache();
      }
    },
    //获取目录-通过分类获取具体科目
    getKsbClassInfo(KsbClassID) {
      var KsbClassID = {
        KsbClassID: KsbClassID,
        // SessionKey: '83B274D55E82E918950BE60B7F81C02D'
      };
      getKsbClassInfo(KsbClassID).then((res) => {
        if (res.data.status == 200) {
          that.KsbClassInfo = res.data.data;
          if (sessionStorage.getItem("subjectCache")) {
            that.onSubjectCache();
          }
        } else {
          return false;
        }
      });
    },
    onDataSearchBox(ChildsBox, identifying) {
      //选择系列，Childs不为空循环Childs调用接口
      var searchData = []; //储存选中的系列科目
      if (identifying == "getKsbClass") {
        for (let i in ChildsBox) {
          var KsbClassID = {
            KsbClassID: ChildsBox[i].KsbClassID,
            // SessionKey: '83B274D55E82E918950BE60B7F81C02D'
          };
          getKsbClassInfo(KsbClassID).then((res) => {
            if (res.data.status == 200) {
              for (let k in res.data.data) {
                //区分是哪个父级下面的分类，往子类添加父类的名字，方便区别 strat
                if (ChildsBox[i].ChildsBox == res.data.data[k].ChildsBox) {
                  res.data.data[k].Name =
                    ChildsBox[i].KsbClassName + "》" + res.data.data[k].Name;
                }
                // end
                searchData.push(res.data.data[k]);
              }
              that.searchData = searchData; //搜索数据
            } else {
              return false;
            }
          });
        }
      } else {
        //选择系列，Childs为空直接调用接口
        var KsbClassID = {
          KsbClassID: ChildsBox,
          // SessionKey: '83B274D55E82E918950BE60B7F81C02D'
        };
        getKsbClassInfo(KsbClassID).then((res) => {
          if (res.data.status == 200) {
            for (let k in res.data.data) {
              searchData.push(res.data.data[k]);
            }
            that.searchData = searchData;
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>
<style>
.example {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
input {
  background: none;
  outline: none;
  border: none;
}

ul,
li,
body {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: aliceblue;
}
</style>
<style lang="less" scoped>
.all {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 23px;
  width: 58px;
  height: 31px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
}

.allActive {
  background: #59afe4 !important;
  color: #ffffff !important;
}

//动画闪烁
.super-input .rozbo {
  background: #ffffff;
  background: -webkit-linear-gradient(#ffffff, #ffffff);
  background: -moz-linear-gradient(#ffffff, #ffffff);
  background: -o-linear-gradient(#ffffff, #ffffff);
  background: -ms-linear-gradient(#ffffff, #ffffff);
  background: linear-gradient(#ffffff, #ffffff);
  border: 1px solid #ffffff;
  border-radius: 5px 0 0 5px;
  box-shadow: 0 2px 0 #ffffff;
  color: #ffffff;
  display: block;
  float: left;
  font-family: "Cabin", helvetica, arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-shadow: 0 -1px 0 #ffffff;
  width: 255px;
  height: 33px;
  position: absolute;
  left: -1px;
  top: -1px;
  z-index: 0;
  border-radius: 4px;
}

.super-input .rozbo {
  -webkit-animation: glow 800ms ease-out infinite alternate;
  -moz-animation: glow 800ms ease-out infinite alternate;
  -o-animation: glow 800ms ease-out infinite alternate;
  -ms-animation: glow 800ms ease-out infinite alternate;
  animation: glow 800ms ease-out infinite alternate;
  background: #222922;
  background: -webkit-linear-gradient(#ffffff, #ffffff);
  background: -moz-linear-gradient(#ffffff, #ffffff);
  background: -o-linear-gradient(#ffffff, #ffffff);
  background: -ms-linear-gradient(#ffffff, #ffffff);
  background: linear-gradient(#ffffff, #ffffff);
  border-color: #59afe4;
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.2), inset 0 0 5px rgba(0, 255, 0, 0.1),
    0 2px 0 #ffffff;
  color: #efe;
  outline: none;
}

@-webkit-keyframes glow {
  0% {
    border-color: #8bcaf1;
    box-shadow: 0 0 5px rgba(139, 202, 241, 0.2),
      inset 0 0 5px rgba(0, 255, 0, 0.1), 0 2px 0 #ffffff;
  }

  100% {
    border-color: #049af7;
    box-shadow: 0 0 20px rgba(4, 154, 247, 0.6),
      inset 0 0 10px rgba(4, 154, 247, 0.2), 0 2px 0 #ffffff;
  }
}

@-moz-keyframes glow {
  0% {
    border-color: #8bcaf1;
    box-shadow: 0 0 5px rgba(139, 202, 241, 0.2),
      inset 0 0 5px rgba(0, 255, 0, 0.1), 0 2px 0 #ffffff;
  }

  100% {
    border-color: #049af7;
    box-shadow: 0 0 20px rgba(4, 154, 247, 0.6),
      inset 0 0 10px rgba(4, 154, 247, 0.2), 0 2px 0 #ffffff;
  }
}

@-o-keyframes glow {
  0% {
    border-color: #8bcaf1;
    box-shadow: 0 0 5px rgba(139, 202, 241, 0.2),
      inset 0 0 5px rgba(0, 255, 0, 0.1), 0 2px 0 #ffffff;
  }

  100% {
    border-color: #049af7;
    box-shadow: 0 0 20px rgba(4, 154, 247, 0.6),
      inset 0 0 10px rgba(4, 154, 247, 0.2), 0 2px 0 #ffffff;
  }
}

@-ms-keyframes glow {
  0% {
    border-color: #8bcaf1;
    box-shadow: 0 0 5px rgba(139, 202, 241, 0.2),
      inset 0 0 5px rgba(0, 255, 0, 0.1), 0 2px 0 #ffffff;
  }

  100% {
    border-color: #049af7;
    box-shadow: 0 0 20px rgba(4, 154, 247, 0.6),
      inset 0 0 10px rgba(4, 154, 247, 0.2), 0 2px 0 #ffffff;
  }
}

@keyframes glow {
  0% {
    border-color: #8bcaf1;
    box-shadow: 0 0 5px rgba(139, 202, 241, 0.2),
      inset 0 0 5px rgba(0, 255, 0, 0.1), 0 2px 0 #ffffff;
  }

  100% {
    border-color: #049af7;
    box-shadow: 0 0 20px rgba(4, 154, 247, 0.6),
      inset 0 0 10px rgba(4, 154, 247, 0.2), 0 2px 0 #ffffff;
  }
}

//动画闪烁 end

.subject_ul .underline:hover {
  border-bottom: 1px solid #000000;
}

.modal {
  background: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px !important;
  z-index: 99;
}

.wrap {
  width: 1200px;
  margin: 0 auto;
}

.active {
  background: #59afe4;
  padding: 0px 0px;
  border-radius: 5px;
  color: #ffffff !important;
}

.active2 {
  color: #59afe4 !important;
}

li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 0;
}

.subject_groupBy {
  display: flex;
  align-items: center;
}

.subject_groupBy-list {
  display: flex;
  flex-direction: row;
}

.subject_left {
  display: flex;
  flex-direction: row;
}

.subject_ul {
  margin-left: 23px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.subject_ul li {
  width: 110px;
  height: 25px;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
  color: #2e2f31;
  letter-spacing: 0px;
  margin-right: 38px;
  margin-bottom: 13px !important;
  // padding: 2px 5px;
  cursor: pointer;
  box-sizing: border-box;
}

.subject_list-title {
  padding: 2px 5px;
}

.subject_title {
  font-weight: Normal;
  height: 25px;
  font-size: 14px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  color: #2e2f31;
  letter-spacing: 0px;
  white-space: nowrap;
}

.subject_titleS {
  font-weight: Normal;
  height: 25px;
  font-size: 14px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  letter-spacing: 0px;
  white-space: nowrap;
}

.subject_all {
  display: flex;
  justify-content: center;
  width: 58px;
  height: 25px;
  font-size: 14px;
  line-height: 25px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-left: 31px;
  cursor: pointer;
}

// 科目切换
.Select-box_input {
  // width: 200px;
  // height: 30px;
  // border: 1px solid #EEEEEE;
  // margin-bottom: 20px;
}

.Select-input-left/deep/.ant-input:focus {
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid white !important;
  box-shadow: none;
  border-bottom: none !important;
}

// .Select-box_input.
.SubjectToChoose {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
}

.Select-input-right {
  display: flex;
  width: 33px;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}

.Select-input-right/deep/.anticon {
  color: #9f9f9f;
}

.subject_titles {
  font-size: 14px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  color: #2e2f31;
  line-height: 38px;
  letter-spacing: 0px;
  white-space: nowrap;
}

.Select {
  margin-left: 23px;
  position: relative;
}

.Select-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 253px;
  height: 31px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
}

.close-circle {
  margin-right: 20px;
}

.msg {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-left: 10px;
  color: #8bcaf1;
}

.Select-input-left {
  font-size: 14px;
}

.Select-box-bottom {
  display: flex;
  flex-direction: row;
}

.Select-box {
  position: absolute;
  left: 0px;
  top: 45px;
  z-index: 999;
}

.Select-box_KsbClassInfo {
  width: 253px;
  height: 310px;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #eeeeee;
  padding: 5px 0px;
  word-break: break-all;
  // position: relative;
}

.timg-box {
  width: 100px;
  height: 100px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -60px;
}

.timg {
  display: inline-block;
  width: 100px;
  height: 100px;
}

.timg-text {
  text-align: center;
  font-size: 14px;
  color: #cccccc;
  margin-top: 5px;
}

.Select-box_getKsbClass {
  width: 253px;
  height: 310px;
  overflow-y: auto;
  white-space: nowrap;
  background: #ffffff;
  border: 1px solid #eeeeee;
  padding: 5px 0px;
  word-break: break-all;
}

// 去除滚动条
.Select-box_KsbClassInfo::-webkit-scrollbar {
  // width: 0px;
}

// 去除滚动条
.Select-box_getKsbClass::-webkit-scrollbar {
  width: 0px;
}

/*滚动条样式*/
.Select-box_KsbClassInfo::-webkit-scrollbar {
  width: 4px;
  /*height: 4px;*/
}

.Select-box_KsbClassInfo::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.Select-box_KsbClassInfo::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

/*滚动条样式*/
.Select-box_getKsbClass::-webkit-scrollbar {
  width: 4px;
  /*height: 4px;*/
}

.Select-box_getKsbClass::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.Select-box_getKsbClass::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.Select-box-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 0px 10px;
  color: #606266;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
  word-break: break-all;
}

.Select-box-list:hover {
  background: #f5f7fa;
}

/*向上*/
.triangle_border_up {
  width: 0;
  height: 0;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #eeeeee;
  /*透明 透明  灰*/
  margin: 0px auto;
  position: absolute;
  left: 20px;
  top: -10px;
}

.triangle_border_up span {
  display: block;
  width: 0;
  height: 0;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #ffffff;
  /*透明 透明  黄*/
  position: absolute;
  top: 1px;
  left: -10px;
}
</style>
