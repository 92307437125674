import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'index.html',
        redirect: '/index.html',
        component: () =>
            import ('../views/Frequency.vue'),
    },
    {
        path: '/Home',
        name: 'Home',
        component: Home,
        children: [{
                path: '/bespoke',
                name: 'bespoke',
                component: () =>
                    import ('../views/bespoke.vue')
            },
            {
                path: '/index.html',
                name: 'index.html',
                component: () =>
                    import ('../views/Frequency.vue')
            },
            {
                path: '/download',
                name: 'download',
                component: () =>
                    import ('../views/download.vue')
            },

            {
                path: '/details/*',
                name: 'details',
                component: () =>
                    import ('../views/details.vue')
            },


        ]
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router