<template>
	<div class="box">
		<div class="fenlei public">
			<div class="typepage">
				<div class="main">
					<div class="main_title">考试宝典 让学习更简单</div>
					<div class="main_text">全新界面, 焕新体验, 专注细节, 高效畅学</div>
					<div class="qrCode_box">
						<div class="qrCode_box_left">
							<div>
								<img src="../assets/download/download_qrcode.png" />
								<div class="qrCode_box_left_text">手机扫码下载</div>
							</div>
							<div style="margin-left:30px;">
								<img src="../assets/download/wechat_qrcode.png" />
								<div class="qrCode_box_left_text">微信扫码咨询</div>
							</div>
						</div>
						<div class="qrCode_box_right">
							<div class="download_btn" @click="onDownload">下载电脑版</div>
							<div class="experience_btn" @click="onExperience">体验网页</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {

			};
		},
		created() {

		},
		mounted() {
         this.$parent.tabAvtion=1; //tab选中
		},
		methods: {
			//下载
			onDownload: function() {
			  window.location.href="http://agent-files.ksbao.com/100088-1/newksbao.exe";
			},
			//体验
			onExperience:function(){
				window.open('http://hzs.ksbao.com/?ac=100088-1')
			}
		}
	};
</script>

<style lang="less" scoped>
	.box .public {
		width: 100%;
	}

	.fenlei {
		// height: 900px;
		background-color: #fff;
	}

	.typepage {
		height: 91vh;
		background: url(../assets/download/download_bj.jpeg);
		margin: 0 auto;
		overflow: hidden;

		// padding-right: ;
	}

	.main {
		width: 1220px;
		position: absolute;
		left: 50%;
		bottom: 30px;
		margin-left: -600px;
	}

	.main_title {
		color: #333333;
		font-size: 50px;
		text-align: center;
	}

	.main_text {
		color: #333333;
		font-size: 25px;
		text-align: center;
	}

	.qrCode_box {
		width:750px;
		display: flex;
		justify-content: space-around;
		margin-top: 78px;
		margin: 0 auto;
		margin-top: 78px;
	}

	.qrCode_box_left {
		display: flex;
		flex-direction: row;
	}

	.download_btn {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 25px;
		color: #FFFFFF;
		width: 180px;
		height: 60px;
		opacity: 1;
		background: #5bb8ff;
		border-radius: 36px;
		cursor: pointer;
	}

	.experience_btn {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 25px;
		color: #FFFFFF;
		width: 180px;
		height:60px;
		opacity: 1;
		margin-top: 32px;
		background: #68ce96;
		border-radius: 36px;
		cursor: pointer;
	}

	.qrCode_box_left_text {
		text-align: center;
		margin-top: 5px;
	}
	
</style>
