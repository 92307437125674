<template>
  <div class="box">
    <div class="modal" v-show="showSubject" @click="onSelect"></div>
    <div class="Header public">
      <div class="Headertp typepage">
        <div class="Headertptu"></div>
        <div class="Headertptu">
          <img class="Headertptu_1" src="../assets/KSBAO-logo.png" alt="" />
          <img class="Headertptu_2" src="../assets/img/by.png" />
          <div class="tab_nav">
            <div
              class="tab_nav_flex"
              :class="tabAvtion == 0 ? 'tab_avtion' : ''"
              @click="onTabAvtion(0)"
            >
              首页
            </div>
            <div
              class="tab_nav_flex"
              :class="tabAvtion == 1 ? 'tab_avtion' : ''"
              @click="onTabAvtion(1)"
            >
              下载
            </div>
          </div>
        </div>
        <div class="youbian">
          <div class="youbian_left">
            <img
              class="youbian_left_img"
              src="../assets/img/phone_top.png"
            />客服热线：400-185-6601
          </div>
          <div class="youbian_right">
            <div class="dlcgde" v-if="cellphone">
              <span>
                <a-popover placement="bottom" v-model="tuichu" trigger="click">
                  <template slot="content">
                    <div>
                      <a slot="content" @click="tuichudenglu" class="diqu"
                        ><span class="nmtc">退出登录</span></a
                      >
                    </div>
                  </template>
                  <span class="sjhm" v-if="yicixing.length == 0">{{
                    cellphone
                  }}</span>
                  <span class="sjhm" v-if="yicixing.length != 0">{{
                    yicixing
                  }}</span>
                  <a-icon type="user" class="tcdly" />
                </a-popover>
              </span>
            </div>
            <div class="longin" @click="showModal" v-if="cellphone == ''">
              <a-icon type="user" />登录
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Contentbox public">
      <!-- <subject @subjects="onSubjects"></subject> -->
      <router-view ref="target-name"></router-view>
      <!-- <div class="typepage">
        <router-view></router-view>
      </div> -->
    </div>
    <div class="fooert">
      <div class="fooert_text">
        Copyright © 2010 - 2021 英腾教育（考试宝典） 授权销售网站
      </div>
      <div class="fooert_text">
        客服微信/QQ：602020500 客服热线：4001856601 售后热线：4007278800
      </div>
      <div class="fooert_text">
        <a href="https://beian.miit.gov.cn/">鄂ICP备10002360号-1</a>
      </div>
    </div>
    <!-- 登录注册手机验证码 -->
    <a-modal
      v-model="loginVisible"
      @ok="handleOk"
      :footer="null"
      :width="782"
      @cancel="zhezhao"
    >
      <div class="neirongde" v-if="popUpShow == '注册'">
        <div class="tupimg">
          <img src="../assets/ksbd.png" alt="" class="tubi" />
        </div>
        <div class="tupimg">
          <span class="zhaohao" @click="mimadl">账号密码登录</span>
          <span class="kuajie">快捷登录注册</span>
        </div>
        <div>
          <span class="kud">
            <a-input placeholder="请输入账号" v-model="userName"
          /></span>
          <!-- <span class="yanzheng"></span> -->
          <div id="captcha"></div>
        </div>
        <div class="ipt">
          <span class="kud">
            <a-input placeholder="请输入验证码" v-model="password"
          /></span>
          <span
            class="yanzheng yanzhengrg"
            v-show="show"
            @click="loginIdentifyingCode"
            >发送验证码</span
          >
          <span class="yanzheng yanzhengrg" v-show="!show"
            >{{ count }}秒重新获取</span
          >
        </div>
        <div class="longinsto" @click="codelogin">登录</div>
        <div class="agreement">
          注册即表示您同意<span>《考试宝典使用协议》、</span
          ><span>《隐私政策》</span>
        </div>
      </div>
      <!-- //设置登录密码 -->
      <div v-if="popUpShow == '设置密码'" class="neirongde">
        <div class="tupimg">
          <img src="../assets/ksbd.png" alt="" class="tubi" />
        </div>
        <div class="tupimg" style="font-size: 30px">设置登录密码</div>
        <span class="zhmiip">
          <a-input placeholder="请输入新密码" v-model="newPassWord" />
        </span>
        <div style="margin: 30px 0px">
          <span class="zhmiip">
            <a-input placeholder="确认新密码" v-model="affirmNewPassWord" />
          </span>
        </div>
        <div class="setPassword_box">
          <div class="setPassword_box_tg" @click="onSkip">跳过</div>
          <div class="setPassword_box_xyb" @click="onSetPassword">下一步</div>
        </div>
        <div class="agreement">
          注册即表示您同意<span>《考试宝典使用协议》、</span
          ><span>《隐私政策》</span>
        </div>
      </div>
      <!-- end- -->
      <!-- 默认密码提示 -->
      <div v-if="popUpShow == '默认密码提示'">
        <div class="tupimg">
          <img src="../assets/ksbd.png" alt="" class="tubi" />
        </div>
        <div class="tupimg" style="font-size: 30px">设置登录密码</div>
        <div class="setPassword_ms">
          <div>
            未设置密码的情况下,<span style="color: red">yt+短信验证码</span>
          </div>
          <p>即为您的登录密码</p>
          <p style="color: #999999; font-size: 15px">(请妥善保管您的密码)</p>
        </div>
        <div class="setPassword_box">
          <div class="setPassword_box_tg" @click="onTemporarilyNotSet">
            暂不设置
          </div>
          <div class="setPassword_box_xyb" @click="onSetPasswordCK">
            设置密码
          </div>
        </div>
        <div class="agreement">
          注册即表示您同意<span>《考试宝典使用协议》、</span
          ><span>《隐私政策》</span>
        </div>
      </div>
    </a-modal>
    <!-- 账号密码登录 -->
    <a-modal
      v-model="passwordLogin"
      @ok="handleOk"
      :footer="null"
      :width="782"
      @cancel="zhezhao"
    >
      <div class="neirongde" v-if="passwordLoginShow == 'login'">
        <div class="tupimg">
          <img src="../assets/ksbd.png" alt="" class="tubi" />
        </div>
        <div class="tupimg">
          <span class="kuajie">账号密码登录</span>
          <span class="zhaohao" @click="kuajiedl">快捷登录注册</span>
        </div>
        <div class="zhmiipb">
          <span class="zhmiip">
            <a-input placeholder="请输入账号" v-model="userName"
          /></span>
        </div>
        <div class="ipt">
          <span class="zhmiip">
            <a-input
              placeholder="请输入密码"
              type="password"
              v-model="password"
          /></span>
        </div>
        <div ref="captcha"></div>
        <div class="longinsto" @click="userlogin">登录</div>
        <div class="agreement">
          <span class="jzmima">
            <!-- <a-checkbox @change="onChange" :checked="sfxz">
							记住密码
						</a-checkbox> -->
          </span>
          <span class="wjmima" @click="wangji">忘记密码</span>
        </div>
      </div>
      <!-- 修改密码 -->
      <div class="neirongde" v-if="passwordLoginShow == 'updatePassword'">
        <div class="tupimg">
          <img src="../assets/ksbd.png" alt="" class="tubi" />
        </div>
        <div class="tupimg" style="font-size: 30px">修改登录密码</div>
        <div class="zhmiipb">
          <span class="zhmiip">
            <a-input placeholder="请输入原密码" v-model="oldPassWordS"
          /></span>
        </div>
        <div class="ipt">
          <span class="zhmiip">
            <a-input placeholder="请输入新密码" v-model="newPassWord"
          /></span>
        </div>
        <div class="ipt">
          <span class="zhmiip">
            <a-input placeholder="请再次输入新密码" v-model="affirmNewPassWord"
          /></span>
        </div>
        <div class="longinsto" @click="onAffirm">完成</div>
      </div>
    </a-modal>
    <!-- 忘记密码 -->
    <a-modal
      v-model="forgotPassword"
      @ok="handleOk"
      :footer="null"
      :width="782"
      @cancel="zhezhao"
    >
      <div class="neirongde">
        <div class="tupimg">
          <img src="../assets/ksbd.png" alt="" class="tubi" />
        </div>
        <div class="tupimg kuajie">
          <!-- <span class="zhaohao " @click="mimadl">账号密码登录333</span>
          <span class="kuajie">快捷登录注册</span> -->
          忘记密码
        </div>
        <div>
          <span class="kud">
            <a-input placeholder="请输入账号" v-model="userName"
          /></span>
          <!-- <span class="yanzheng"></span> -->
          <div id="captcha"></div>
        </div>
        <div class="ipt">
          <span class="kud">
            <a-input placeholder="请输入验证码" v-model="password"
          /></span>
          <span
            class="yanzheng yanzhengrg"
            v-show="show"
            @click="loginIdentifyingCode"
            >发送验证码</span
          >
          <span class="yanzheng yanzhengrg" v-show="!show"
            >{{ count }}秒重新获取</span
          >
        </div>
        <div class="ipt">
          <span class="kud">
            <a-input
              placeholder="请输入新密码"
              type="password"
              v-model="xinpassword"
          /></span>
        </div>
        <div class="longinsto" @click="wjmm">完成</div>
      </div>
    </a-modal>
    <!-- 修改密码弹窗 -->
    <div class="pop_up" v-if="passwordPromptShow">
      <div class="pop_up_box">
        <div class="msg_box_flex">
          <div class="msg_text">
            您当前的密码过于简单，为保证您的账户安全，建议立即修改
          </div>
        </div>
        <div class="msg_box_bttom">
          <!-- @click="onHome" -->
          <!-- @click="onChangePassword" -->
          <div class="msg_box_left msg_btn" @click="onHome">暂不修改</div>
          <div class="msg_box_right msg_btn" @click="onUpdatePassword">
            设置密码
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- <script src="https://cstaticdun.126.net/load.min.js?t=201903281201"></script> -->
<!-- 15778411339 -->
<script>
var that;
import subject from "../components/subject.vue";
import { location } from "../utils/LocationUtil.js";
import {
  getSearch,
  sendlogincode,
  checkUser,
  sendcode,
  codelogin,
  userlogin,
  resetPassword,
  changePassword,
} from "../api/api";
export default {
  inject: ["reload"],
  components: {
    subject,
  },
  data() {
    return {
      visible: false,
      tuichu: false,
      loginVisible: false,
      validate: "",
      show: true,
      count: "",
      userName: "",
      password: "",
      xinpassword: "",
      passwordLogin: false,
      forgotPassword: false,
      dlcg: true,
      sfxz: false,
      morenxunzhong: false,
      dianhua: "",
      yicixing: "",
      denglude: 0,
      province: "", //获取位置
      xzkey: 1,
      i: 1,
      ii: 1,
      xqing: false, //首页详情导航
      orderform: true,
      shuru: "",
      dlogin: true,
      showSubject: false, //显示科目
      searchData: [], //科目
      appPName: "", //input
      cellphone: "",
      token: 0,
      messageSearch: false, //资讯搜索导航
      orderformFlag: true, // 资讯中心样式类名控制
      tabAvtion: 0,
      popUpShow: "注册", //修改密码弹窗
      getLoginItemData: {}, //储存登录返回的数据
      wzcd: "", //判断是否注册
      passwordCode: "", //保存用户默认密码 用于设置密码
      oldPassWordS: "", //用户原密码
      newPassWord: "", //设置密码 新密码
      affirmNewPassWord: "", //设置密码 再次确认密码
      passwordPromptShow: false, //显示登录提示修改密码框
      passwordLoginShow: "login", //默认显示登录框
    };
  },
  created() {
    this.hur();
    if (localStorage.getItem("wjmm") == 0) {
      this.forgotPassword = true;
      this.password = "";
      this.userName = "";
    }
    this.token = JSON.parse(sessionStorage.getItem("token"));
  },
  mounted() {
    that = this;
    this.getLocation();
    if (sessionStorage.getItem("cellphone")) {
      that.cellphone = sessionStorage.getItem("cellphone");
    } else {
      that.cellphone = "";
    }
    that.i = sessionStorage.getItem("isSelect");
    // 刷新页面的时候仍显示当前的tab和标题内容
    let pathname = window.location.pathname;
    if (["/index.html", "/livebroadcast", "/news"].indexOf(pathname) === -1) {
      // mbxDom[0].style.display = "none";
    } else {
      let isSelect = sessionStorage.getItem("isSelect");
      // this.sss(isSelect || 1);
    }
  },
  methods: {
    //首页 下载切换
    onTabAvtion: function (e) {
      this.tabAvtion = e;
      if (e == 0) {
        this.$router.push({
          path: "/",
        });
      } else {
        this.$router.push({
          path: "/download",
        });
      }
    },
    onSubjectsChange: function () {
      if (that.shuru == "") {
        this.$router.push({
          path: "/index.html",
        });
      } else {
        this.$router.push({
          path: "/whole",
        });
      }
      let data = {
        appPName: that.shuru,
      };
      getSearch(data).then((res) => {
        if (that.shuru != "") {
          that.showSubject = true;
        }
        console.log("es.data.data", res.data.data);
        if (res.data.status == 200) {
          if (res.data.data.length != 0) {
            that.searchData = res.data.data; //可科目
          } else {
            that.searchData = res.data.data; //可科目
          }
        }
      });
    },
    onSearSubject: function (searchDataItem) {
      if (JSON.parse(sessionStorage.getItem("token")) != 1) {
        this.$refs["target-name"].freeFegistData(searchDataItem.AppID);
      } else {
        this.$refs["target-name"].pricelist(searchDataItem.AppID);
      }
      that.showSubject = false;
      that.onSubjectCache(searchDataItem.AppID, searchDataItem.Name);
      that.shuru = searchDataItem.Name;
    },
    onSelect: function () {
      that.showSubject = false;
    },
    onSubjectCache(AppID, Name) {
      let data = {
        AppID: AppID,
        KsbClass: that.KsbClass,
        MediClassID: 1, //1医学职业 2医学实用技术 0其他职业
        KsbClassID: 0, //默认选中正高卫生职称--临床医学类父级
        KsbClassID2: 180, //默认正高卫生职称
        KsbClass: [], //第二层
        KsbClassInfo: [], //科目
        Childs: 2,
        appPName: Name,
      };
      localStorage.setItem("subjectCache", JSON.stringify(data));
    },
    // 导航点击切换
    // sss(i) {
    //   let mbxDom = document.getElementsByClassName("mbx");
    //   mbxDom[0].style.display = "block";
    //   var key = i;
    //   sessionStorage.setItem("isSelect", key);
    //   if (key == 1) {
    //     this.xzkey = 1;
    //     this.xqing = false;
    //     this.messageSearch = false;
    //     this.$router.push({
    //       path: "/index.html",
    //     });
    //   } else if (key == 2) {
    //     this.xzkey = 2;
    //     this.$router.push({
    //       path: "/livebroadcast",
    //     });
    //     this.xqing = false;
    //     this.messageSearch = false;
    //   } else if (key == 3) {
    //     window.open("http://ksbao.com/pc/");
    //   } else if (key == 4) {
    //     this.xzkey = 4;
    //     this.orderformFlag = true;
    //     this.$router.push({
    //       path: "/news",
    //       // path: "/myOrderform",
    //     });
    //   } else if (key == 5) {
    //     this.xzkey = 5;
    //     this.$router.push({
    //       path: "/myOrderform",
    //     });
    //     this.xqing = false;
    //     this.orderform = true;
    //     this.messageSearch = false;
    //   }
    //   this.i = i;
    //   that.shuru = "";
    // },

    tobdian(key) {
      var key = key;

      if (key == 0) {
        this.$router.push({
          path: "/index.html",
        });
        this.xqing = false;
        this.orderform = false;
        this.messageSearch = false;
        that.shuru = "";
        this.orderformFlag = false;
        this.i = 1;
        this.xzkey = 1;
      } else if (key == 1) {
        this.$router.push({
          path: "/index.html",
        });
        this.xqing = false;
        this.orderform = false;
        this.messageSearch = false;
        this.orderformFlag = false;
        that.shuru = "";
        this.i = 1;
        this.xzkey = 1;
      } else if (key == 2) {
        this.$router.push({
          path: "/livebroadcast",
        });
        that.shuru = "";
        this.orderform = false;
        this.messageSearch = false;
      } else if (key == 4) {
        this.$router.push({
          path: "/news",
        });
        // this.$router.push({
        //   path: "/myOrderform",
        // });
        that.shuru = "";
        this.orderformFlag = true;
      }
    },
    /**获取地图定位*/
    getLocation() {
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            that.province = result.province;
            let city = result.city;
          }
        });
      });
    },

    //获取选中科目值
    onSubjects: function (item) {
      console.log("item", item);
    },
    hur() {
      var captchaIns;
      var that = this;
      initNECaptcha(
        {
          element: "#captcha",
          captchaId: "9eb25e37fcd34904914c9986b1b0e265",
          mode: "popup",
          width: "100px",
          // enableClose: true, // 由业务方控制验证码弹框关闭
          // feedbackEnable: false, // 业务方关闭反馈入口
          onClose: function () {
            // 弹出关闭结束后将会触发该函数
          },
          onVerify: function (err, data) {
            that.validate = data.validate;
            // if (!err) {
            // 验证成功后，调用close方法关闭弹框（enableClose为true时调用）
            //captchaIns.close()
            //}
          },
        },
        function (instance) {
          // 初始化成功后得到验证实例instance，可以调用实例的方法
          captchaIns = instance;
        },
        function (err) {
          // 初始化失败后触发该函数，err对象描述当前错误信息
        }
      );
    },
    tuichudenglu() {
      this.tuichu = false;
      // this.denglude = 0;
      this.dlogin = !this.dlogin;

      sessionStorage.setItem("token", 0);
      sessionStorage.removeItem("cellphone");
      that.cellphone = "";
      this.token = 0;

      this.getFreeFegist(); //重新触发免费试用方法，获取缓存的游客guid
      this.$refs["target-name"].pricelist(); //班次
      //判断是否有勾选，没有勾选就把input账号密码清掉
      if (that.sfxz == false) {
        this.password = "";
        this.userName = "";
      }
    },
    personal() {
      this.xzkey = 4;
      this.$router.push({
        path: "/myOrderform",
      });
      this.xqing = false;
    },

    hide() {
      console.log(111);
      this.visible = false;
      this.hur();
    },
    kuajiedl() {
     
      this.password = "";
      this.userName = "";
      this.loginVisible = true;
      this.passwordLogin = false; //密码登录

      this.hur();
      this.password = "";
      this.userName = "";
    },
    showModal() {
      this.passwordLogin = true;
      this.hur();
    },
    wangji() {
      this.hur();
      this.forgotPassword = true;
      this.passwordLogin = false;
      this.password = "";
      this.userName = "";
      this.reload();
      localStorage.setItem("wjmm", 0);
    },
    mimadl() {
      this.userName = "";
      this.password = "";
      this.passwordLogin = true;
      this.loginVisible = false;
      this.hur();
    },
    zhezhao() {
      localStorage.setItem("wjmm", 1);
      this.userName = "";
      this.password = "";
      // this.reload();
      // this.$router.go(0);
    },
    onChange() {
      this.sfxz = !this.sfxz;
      if (this.sfxz == true) {
        localStorage.setItem("xgwpassword", this.$md5(this.password));
        localStorage.setItem("xgwusername", this.userName);
        localStorage.setItem("dlzt", 1);
      } else {
        localStorage.removeItem("xgwpassword");
        localStorage.removeItem("xgwusername");
        localStorage.setItem("dlzt", 0);
      }
    },
    handleOk(e) {
      console.log("6666666", e);
      // this.loginVisible = false; //快捷登录
      // this.passwordLogin = false; //密码登录
      // this.forgotPassword = false; //忘记密码
      this.hur();
      this.$router.go(0);
    },
    callback(key) {
      if (key == 1) {
        this.$router.push({
          path: "/index",
        });
        this.xzkey = 1;
      } else if (key == 2) {
        this.xzkey = 2;
        this.$router.push({
          path: "/livebroadcast",
        });
      } else if (key == 3) {
        window.open("http://ksbao.com/pc/");
      } else if (key == 4) {
        this.xzkey = 4;
        // this.$router.push({
        //   path: "/myOrderform",
        // });
        this.$router.push({
          path: "/news",
        });
      }
    },
    //登录获取验证码
    loginIdentifyingCode() {
      console.log(this.validate);
      var data = {
        phone: this.userName,
        agentCode: "100088-1",
        op: "regist",
        isRepeat: "0",
        captchaId: "9eb25e37fcd34904914c9986b1b0e265",
        validate: this.validate,
        clienttype: "Android原生",
        os: "Android原生",
      };
      var getdata = {
        userName: this.userName,
        agentCode: "100088-1",
      };
      console.log("phone", this.userName);
      var sendcodeDta = {
        phone: this.userName,
        agentCode: "100088-1",
        op: "regist",
        isRepeat: "0",
        product: "考试宝典",
        captchaId: "9eb25e37fcd34904914c9986b1b0e265",
        validate: this.validate,
      };
      console.log("sendcodeDta", sendcodeDta);
      var that = this;
      //检查账号是否已注册
      checkUser(getdata).then((res) => {
        if (res.data.status == 201 && res.data.msg == "该用户未注册") {
          //  this.$toast.success('成功文案');
          that.exist = false;
          console.log("shibai");
        } else if (res.data.status == 200 && res.data.msg == "该用户已注册") {
          console.log("chenggong");
          that.exist = true;
        }
        console.log("getdata", res);
      });
      //注册登录时发送手机验证码
      if (this.userName.length < 11 || this.userName.length == 0) {
        this.$message.info("请输入正确的手机号");
      } else if (this.validate.length == 0) {
        this.$message.info("请完成手机验证");
      } else {
        //倒计时
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
        sendlogincode(data).then((res) => {
          console.log("我是验证码的", res);
        });
      }
    },
    //修改密码
    onSetPassword: function () {
      var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/; // 只为纯数字
      if (this.newPassWord == "") {
        this.$message.info("密码不能为空");
        return;
      } else if (this.newPassWord.length < 8 || this.newPassWord.length > 20) {
        this.$message.info("密码长度必须为8-20个字符");
        return;
      } else if (!reg.test(this.newPassWord)) {
        this.$message.info("密码需要包含字母和数字");
        return;
      }
      if (this.newPassWord != this.affirmNewPassWord) {
        this.$message.info("两次输入的密码不一致");
        return;
      }

      var data = {
        guid: JSON.parse(sessionStorage.getItem("loginData")).guid,
        oldPassword: this.$md5(this.passwordCode),
        // oldPassword: this.$md5("123456"),
        newPassword: this.$md5(this.newPassWord),
      };
      changePassword(data).then((res) => {
        if (res.data.status == 200) {
          this.$message.info("密码设置成功");
          this.popUpShow = "注册";
          this.loginVisible = false; //快捷登录
          this.initializeInputData();
        }
      });
    },
    //跳过
    onSkip: function () {
      this.popUpShow = "默认密码提示";
    },
    //暂不设置
    onTemporarilyNotSet: function () {
      this.popUpShow = "注册";
      this.loginVisible = false; //快捷登录
      this.initializeInputData();
    },
    //显示设置密码弹窗
    onSetPasswordCK: function () {
      this.popUpShow = "设置密码";
    },
    //老用户暂不设置
    onHome: function () {
      this.loginLocalStorageData(this.getLoginItemData); //登录需要缓存的数据
      this.passwordPromptShow = false; //显示修改密码提示框
    },
    //更新密码
    onUpdatePassword: function () {
      this.passwordLogin = true; //
      this.passwordPromptShow = false; //显示修改密码提示框
      this.passwordLoginShow = "updatePassword"; //显示修改密码框
      this.loginLocalStorageData(this.getLoginItemData); //登录需要缓存的数据
    },
    onAffirm: function () {
      var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/; // 只为纯数字
      if (this.oldPassWordS == "") {
        this.$message.info("原密码能为空");
        return;
      }
      if (this.newPassWord.length < 8 || this.newPassWord.length > 20) {
        this.$message.info("密码长度必须为8-20个字符!");
        return;
      } else if (!reg.test(this.newPassWord)) {
        this.$message.info("密码需要包含字母和数字");
        return;
      } else if (this.affirmNewPassWord !== this.newPassWord) {
        this.$message.info("两次输入的密码不一致！");
        return;
      }
      console.log("datadatadata", this.oldPassWordS, this.newPassWord);
      let data = {
        oldPassword: this.$md5(this.oldPassWordS),
        newPassword: this.$md5(this.newPassWord),
        guid: JSON.parse(sessionStorage.getItem("loginData")).guid,
      };
      changePassword(data).then((res) => {
        if (res.data.status == 200) {
          this.$message.info("修改成功");
          this.passwordLogin = false; //
          this.initializeInputData();
        } else {
          this.$message.info("修改失败");
          this.initializeInputData();
        }
      });
    },
    //初始化输入框
    initializeInputData() {
      this.oldPassWordS = "";
      this.oldPassWord = ""; // 原密码
      this.newPassWord = ""; // 新密码
      this.affirmNewPassWord = ""; // 确认新密码
      this.userName = "";
      this.password = "";
    },
    //修改密码
    //获取短信验证码登录
    codelogin() {
      var that = this;
      // 验证用户是否新用户
      var dataphone = {
        userName: that.userName,
        agentCode: "100088-1",
      };
      //检查账号是否已注册
      checkUser(dataphone).then((res) => {
        if (res.data.status == 201 && res.data.msg == "该用户未注册") {
          this.wzcd = res.data.msg;
        }
      });
      // end -----------------------------------------------------

      var password = `yt${this.password}`;
      var data = {
        userName: this.userName,
        verifyCode: this.password,
        agentCode: "100088-1",
        password: this.$md5(password),
      };
      codelogin(data).then((res) => {
        if (res.data.status == "200") {
          if (this.wzcd == "该用户未注册") {
            this.popUpShow = "设置密码";
            this.passwordCode = password; //用户默认密码
          } else {
            this.loginVisible = false; //快捷登录
          }

          sessionStorage.setItem("loginData", JSON.stringify(res.data.data));
          sessionStorage.setItem("cellphone", that.userName);

          //2021年6月14日吴冠晴修改  购买课程快捷登录无效的问题，加下面这两句
          sessionStorage.setItem("danci", that.userName);
          sessionStorage.setItem("token", 1);

          that.cellphone = sessionStorage.getItem("cellphone");
          that.$refs["target-name"].pricelist(localStorage.getItem("AppID"));
          that.denglude = 1;
          that.dlcg = !that.dlcg;
          that.token = JSON.parse(sessionStorage.getItem("token"));
        } else {
          that.$message.info("手机号或手机验证码错误");
        }
      });
    },
    //账号密码登录  15778411339
    userlogin() {
      var data = {
        username: this.userName,
        password: this.$md5(this.password),
      };
      if (
        localStorage.getItem("xgwpassword") != undefined &&
        localStorage.getItem("xgwusername") != undefined
      ) {
        data.username = localStorage.getItem("xgwusername");
        data.password = localStorage.getItem("xgwpassword");
      }
      console.log("mimade", data);
      if (this.userName.length == 0 || this.password.length == 0) {
        this.$message.info("账号密码不能为空");
      } else {
        userlogin(data).then((res) => {
          if (res.data.msg === "用户名或密码错误.") {
            this.$message.info("用户名或密码错误");
          } else {
            var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/; // 只为纯数字
            if (this.password.length < 8 || !reg.test(this.password)) {
              this.passwordPromptShow = true; //显示修改密码提示框
              this.passwordLogin = false; //隐藏登录弹窗
              this.getLoginItemData = res; //储存登录返回的数据
              return;
            }
            this.loginLocalStorageData(res); //登录需要缓存的数据
            this.$message.info("登录成功");
            this.passwordLogin = false; //隐藏登录弹窗

            // this.reload();
            // that.$router.push({ path: "/index" });
          }
        });
      }
    },
    //登录需要缓存的数据
    loginLocalStorageData(res) {
      sessionStorage.setItem("loginData", JSON.stringify(res.data.data));
      sessionStorage.setItem("cellphone", that.userName);
      that.cellphone = sessionStorage.getItem("cellphone");
      sessionStorage.setItem("token", 1);
      // this.dlcg = !this.dlcg;
      // this.$message.info("登录成功");
      this.dlogin = !this.dlogin;
      if (this.sfxz == true) {
        localStorage.setItem("xgwpassword", that.$md5(that.password));
        localStorage.setItem("xgwusername", that.userName);
      } else {
        sessionStorage.setItem("danci", that.userName);

        // localStorage.removeItem("xgwpassword");
        // localStorage.removeItem("xgwusername");
      }
      this.denglude = 1;
      console.log("denglude", this.denglude);
      // this.passwordLogin = false; //密码登录
      localStorage.setItem("dlzt", 1);
      // this.$refs["target-name"].pricelist(res.data.data.appID);
      this.token = JSON.parse(sessionStorage.getItem("token"));
    },
    //忘记密码
    wjmm() {
      var data = {
        phone: this.userName,
        verifyCode: this.password,
        password: this.$md5(this.xinpassword),
        agentCode: "100088-1",
      };
      resetPassword(data).then((res) => {
        if (res.data.status == 200) {
          this.$message.info("修改密码成功");
          this.reload();
          this.passwordLogin = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.modal {
  background: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px !important;
  z-index: 99;
}

.tcdly {
  cursor: pointer;
}

.tcdly:hover {
  opacity: 0.7;
}

.dwimg {
  margin-right: 10px;
}

.choice {
  text-align: center;
  margin: 0 20px;
  line-height: 40px;
  padding: 0 4px 10px 0px;
  font-size: 16px;
  letter-spacing: 2px;
  cursor: pointer;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
}

.choice:hover {
  color: #62befc;
  // border-bottom: 2px solid #62BEFC;
}

.active {
  border-bottom: 2px solid #62befc;
  color: #62befc;
}

.smapp {
  position: relative;
  top: 140px;
  font-size: 24px;
  color: #eb353f;
  left: 40px;
}

.liimgrt {
  position: relative;
  top: -218px;
  left: 250px;
}

.box .yyimg .gzhsm {
  text-align: center;
  width: 500px;
  position: relative;
  top: 130px;
  left: -286px;
  font-size: 24px;
  color: #eb353f;
}

.smxz {
  text-align: center;
  margin-top: -20px;
  font-size: 20px;
  color: #76777a;
}

.mbx {
  width: 1200px;
  margin: 0 auto;
  padding-top: 10px;
}

// .orderform {
//   width: 1200px;
//   height: 100%;
//   background-color: #fff;
//   margin: 0 auto;
// }
.orderform {
  float: left;
  height: 100%;
  background-color: #fff;
  margin: 52px auto 0;
}

.title {
  cursor: pointer;
  color: #000;
}

.title:hover {
  color: #62befc;
  border-bottom: 1px solid #62befc;
  padding-bottom: 4px;
}

.indexs {
  color: #a09494;
}

.box /deep/.ant-tabs-nav {
  color: #2e2f31;
}

.kud/deep/.ant-input {
  width: 335px;
  height: 46px;
  z-index: 500000;
}

.zhmiip/deep/.ant-input {
  height: 46px;
}

.zhmiipb {
  margin-bottom: 40px;
}

.jzmima {
  float: left;
}

.wjmima {
  float: right;
}

.yanzheng {
  color: #62befc;
  border: 1px solid #d9d9d9;
  padding: 12.5px 24px;
  font-size: 14px;
  position: relative;
  left: -3px;
  border-left: 0;
  border-radius: 0px 4px 4px 0px;
}

.xzksbd {
  cursor: pointer;
}

.yanzheng:hover {
  cursor: pointer;
}

.ipt {
  margin-bottom: 35px;
}

.zhaohao {
  font-size: 20px;
  cursor: pointer;
}

.yanzhengrg {
  padding-right: 34px;
}

.kuajie {
  font-size: 24px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  color: #303030;
  cursor: pointer;
}

.nmtc {
  font-size: 14px;
  margin-right: 10px;
}

.longinsto {
  width: 474px;
  height: 60px;
  text-align: center;
  background: #62befc;
  font-size: 24px;
  line-height: 60px;
  color: #fff;
  border-radius: 4px;
  margin-bottom: 60px;
}

.longinsto:hover {
  cursor: pointer;
}

.tupimg {
  text-align: center;
  margin-bottom: 20px;
}

.tupimg img {
  width: 226px;
  height: 72px;
}

.tubi {
  width: 226px;
  height: 72px;
}

.tupimg span {
  margin: 0 40px;
}

.neirongde {
  // text-align: center;
  margin: 34px 120px 15px 120px;
}

.agreement {
  height: 10px;
  text-align: center;
  margin-bottom: 0px;
}

.agreement span:hover {
  cursor: pointer;
}

.agreement span {
  color: #62befc;
}

.dlcgde {
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 32px;
  margin-right: 20px;
}

.sjhm {
  margin-left: 20px;
  margin-right: 4px;
  cursor: pointer;
}

.sjhm:hover {
  opacity: 0.7;
}

//头部

.blft {
  margin-right: 10px;
}

.diqu {
  margin: 10px;
}

.goto span {
  font-size: 14px;
  color: #999999;
}

.box .goto .qhcs {
  color: #62befc !important;
  cursor: pointer;
}

.goto .tbleft {
  margin-top: 5px;
  float: left;
}

.goto .tbr {
  margin-top: 5px;
  float: right;
}

.tbrlt {
  margin-left: 14px;
  padding-bottom: 4px;
}

.tbrlt a:hover {
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: 1px solid #62befc;
}

.Headertptu {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  float: left;
}

.Headertptu_1 {
  width: 124px;
  height: 41px;
  margin-right: 24px;
}

.Headertptu_2 {
  width: 124px;
  height: 41px;
  margin-right: 60px;
}

.youbian {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  float: right;
  font-size: 14px;
  position: relative;
}

.box/deep/.ant-tabs-bar {
  margin: 0 0 16px 0;
  border-bottom: 0px solid #e8e8e8;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  border-radius: 18px;
}

.longin {
  cursor: pointer;
  margin-left: 45px;
  text-align: center;
  line-height: 36px;
  width: 100px;
  height: 36px;
  background: #62befc;
  border-radius: 18px;
  color: #fff;
}

.longin:hover {
  opacity: 0.9;
}

// 底部
.presentation ul {
  width: 264px;
  padding: 0;
}

.presentation .yy li {
  width: 264px;
  list-style-type: none;
  font-size: 14px;
  padding: 0;
  color: #fff;
  list-style-type: none !important;
}

.goto {
  font-size: 14px;
  font-family: Source Han Sans CN Light, Source Han Sans CN Light-Light;
  font-weight: 300;
  color: #62befc;
  height: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.typepage {
  width: 1200px;
  height: 100%;
  background-color: #fff;
  margin: 0 auto;
}

.typepagea {
  width: 1200px;
  height: 100px;
  background-color: #fff;
  margin: 0 auto;
}

.Header {
  height: 72px;
  box-shadow: 0px 20px 5px -20px #ccc;
}

.Header .Headertp {
  height: 72px;
  z-index: 5555;
}

.Footertp .typepage {
  background-color: #272c32;
}

.Footertp {
  height: 313px;
  background-color: #272c32;
}

.Footerbt {
  height: 92px;
  background-color: #000;
}

.Footerbt .typepage {
  background-color: #000;
}

.yqts {
  padding-top: 61px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.yqts div {
  width: 264px;
  font-size: 14px;
}

.gyyqts img {
  width: 110px;
  height: 168px;
}

.box .presentation .primary {
  margin-bottom: 30px !important;
  list-style-type: none !important;
}

.liimg {
  float: left;
  width: 122px;
  height: 120px;
}

.primary .liimg {
  width: 122px;
  height: 120px;
}

.record {
  font-size: 14px;
  text-align: center;
  padding-top: 14px;
  color: #fff;
}

.box .buds {
  width: 364px;
  list-style-type: none;
  color: #fff;
}

.code-box {
  display: flex;
  flex-direction: row;
}

.code-box-left {
  display: flex;
  flex-direction: column;
  margin-right: 76px;
}

.code-box-right {
  display: flex;
  flex-direction: column;
}

.code-box-text {
  display: block;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
  color: #fff;
  white-space: nowrap;
  letter-spacing: 5px;
}

.box .presentation ul li {
  margin: 4px 0;
  font-size: 16px;
}

.box .presentation ul li a {
  color: #fff;
}

.box .presentation ul li a:hover {
  border-bottom: 1px solid #ccc;
}

.box .presentation ul .yinyue a {
  color: #555555;
}

.box .presentation ul .yinyue a:hover {
  border-bottom: 1px solid #555555;
}

.Select-box_KsbClassInfo {
  width: 240px;
  height: 310px;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #eeeeee;
  padding: 5px 0px;
  word-break: break-all;
  position: absolute;
  left: 0px;
  top: 100;
  z-index: 999;
}

.Select-box-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 0px 10px;
  color: #606266;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
  word-break: break-all;
}

.Select-box-list:hover {
  background: #f5f7fa;
}

// 去除滚动条
.Select-box_KsbClassInfo::-webkit-scrollbar {
  width: 0px;
}

// 去除滚动条
.Select-box_getKsbClass::-webkit-scrollbar {
  width: 0px;
}

.timg-box {
  width: 100px;
  height: 100px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -80px;
}

.timg {
  display: inline-block;
  width: 100px;
  height: 100px;
}

.timg-text {
  text-align: center;
  font-size: 14px;
  color: #cccccc;
  margin-top: 5px;
}

/*滚动条样式*/
.Select-box_KsbClassInfo::-webkit-scrollbar {
  width: 4px;
  /*height: 4px;*/
}

.Select-box_KsbClassInfo::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.Select-box_KsbClassInfo::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

// -----
.tab_nav {
  display: flex;
  flex-direction: row;
}

.tab_nav_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 30px;
  font-size: 14px;
  cursor: pointer;
}

.tab_avtion {
  border-bottom: 1px solid #62befc;
  color: #62befc;
}

.tab_nav_flex + .tab_nav_flex {
  margin-left: 60px;
}

//
.fooert {
  text-align: center;
  color: #cbd0d5;
  // position: fixed;
  // left: 0;bottom: 0;
  width: 100%;
  height: 135px;
  line-height: 27px;
  opacity: 1;
  background: #131313;
  padding-top: 33px;
  box-sizing: border-box;
}

.fooert_text {
}

.youbian_left {
  display: flex;
  align-items: center;
}

.youbian_left_img {
  margin-right: 5px;
}

// /
.setPassword_box {
  width: 474px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 20px;
}

.setPassword_box_tg {
  font-size: 20px;
  cursor: pointer;
}

.setPassword_box_xyb {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 90px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  background: #62befc;
}

.setPassword_ms {
  text-align: center;
  font-size: 20px;
}

// 弹窗-------------
.msg_box_flex {
  width: 622px;
  height: 290px;
  padding: 30px;
  box-sizing: border-box;
}

.pop_up_modal {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 100px !important;
  z-index: 999999;
}

.pop_up_box {
  width: 622px;
  height: 300px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -311px;
  margin-top: -191px;
  z-index: 10;
  background: #ffffff;
  box-sizing: border-box;
  z-index: 1000000000000000000000000;
}

.msg_box_bttom {
  display: flex;
  flex-direction: row;
  width: 622px;
  height: 90px;
  border-top: 1px solid #e5e5e5;
  position: absolute;
  bottom: 0px;
  left: 0;

  .msg_box_left {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-right: 1px solid #e5e5e5;
  }

  .msg_box_right {
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: #2492ff;
    align-items: center;
  }

  .msg_btn {
    width: 311px;
    height: 90px;
    font-size: 32px;
  }
}

.msg_text {
  font-size: 30px;
}

.msg_text_tow {
  color: rgb(127, 127, 127);
  font-size: 28px;
  margin-top: 10px;
}

.msg_box_bttom_t {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 622px;
  height: 90px;
  border-top: 1px solid #e5e5e5;
  // position: absolute;
  // bottom: 0px;
  // left: 0;

  .msg_box_bttom_text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    color: #2492ff;
  }
  .pop_up{
	  z-index: 100000000000000000000;
  }
}
</style>
