<template>
  <div class="box">
    <floatingSidebar></floatingSidebar>
    <!-- <a-modal v-model="videoModalTrue" :footer="null" :width="1200" @cancel='onCancelModal'>
			<div class="KsbClassInfoTitle">{{KsbClassInfoTitle}}</div>
			<div class="video-box">
				<el-col :span="24">
					<el-col :span="18">
						<div class="prism-player" id="J_prismPlayer"></div>
					</el-col>
					<el-col :span="6">
						<div class="el-menu-vertical-demo-box">
							<el-menu class="el-menu-vertical-demo" :default-active="defaultActive" :unique-opened='uniqueOpened' @open="onVideoOpen"
							 @select="onVideoSelect">
								<el-submenu :key="index" :index=" JSON.stringify(item.bookID)" v-for="(item,index) in videoList">
									<template slot="title">
										<span>{{item.bookName}}</span>
									</template>
									<el-menu-item-group>
										<el-menu-item :key="indexs" :index="JSON.stringify(items.names[0].id)" v-for="(items,indexs) in videoSublevelList">{{items.chapterName}}</el-menu-item>
									</el-menu-item-group>
								</el-submenu>
							</el-menu>
						</div>
					</el-col>
				</el-col>
			</div>

		</a-modal> -->
    <!-- <floatingSidebar></floatingSidebar> -->
    <!-- 轮播图 start-->
    <div class="ant-carousel-box ant-carousel" style="overflow: hidden">
      <a-carousel arrows autoplay>
        <div class="banner-img">
          <!-- <img src="https://yingedu-deep-agent.oss-cn-hangzhou.aliyuncs.com/banner/new-year1.png" /> -->
          <img src="../assets/img/lbt.png" />
        </div>
      </a-carousel>
      <div class="qrCode-box">
        <img src="../assets/bannerQrCode.png" class="qrCode-img" />
        <div class="qrCode-text">
          <div class="qrCode-text-one">微信扫一扫</div>
          <div class="qrCode-text-two">关注公众号 听课/做题更方便</div>
        </div>
      </div>
      <div class="download_box">
        <div class="experience_btn" @click="onExperience">
          <img src="../assets/img/experience_img.png" />立即免费试用
        </div>
        <div class="download_btn" @click="onDownload">
          <img src="../assets/img/download_img.png" />下载电脑版
        </div>
      </div>
    </div>
    <!-- v-if="item.VerName !=='临床病例' && item.VerName !=='用药指南' && item.VerName !=='影像图鉴' && item.VerName !=='临床路径' -->
    <!-- && item.VerName !=='疾病知识' && item.VerName !=='医学名词' && item.VerName !=='诊断助手'" -->
    <!-- 轮播图 end-->
    <div class="fenlei public">
      <div class="typepage">
        <div class="subject_headings">
          <div class="subject_headings_left">
            <span class="subject_headings_left-line"></span>
            <span class="subject_headings_left-text">专业分类</span>
          </div>
          <div class="subject_headings_center">
            <span class="subject_headings_center_span">热门搜索:</span>
            <ul class="subject_headings_center_ul">
              <li
                class="subject_headings_center_li"
                v-for="(item, index) in hotSearch"
                @click="onHotSearch(item)"
              >
                {{ item.Name }}
              </li>
            </ul>
          </div>
          <div class="subject_headings_right">
            <div class="search_input-box">
              <span class="search_icon"> <a-icon type="search" /></span>
              <input
                type="text"
                placeholder="输入科目名称或科目代码"
                v-model="appPName"
                class="search_input"
              />
              <a-icon
                type="close-circle"
                class="close-circle"
                v-show="appPName != ''"
                @click="onClose"
              />
            </div>
            <div class="search_input_btn" @click="onClickSearch">
              <span class="search_load">
                <a-spin size="small" v-show="searchLoading"
              /></span>
              <span>{{ searchLoading ? "搜索中" : "搜索" }}</span>
            </div>
          </div>
        </div>
        <!-- 科目  -->
        <div class="subject_box">
          <div class="subject_box_left">
            <div class="box_left_parent">
              <div
                class="box_left_parent_list"
                v-for="(item, index) in categoryData"
                v-show="item.MediClassID != 0"
              >
                <div
                  class="box_left_parent_span"
                  :class="item.parentOpen ? 'parentAvtion' : ''"
                  @click="onParentOpen(item, index, 'parent')"
                >
                  <span>{{ item.KsbClassName }}</span>
                  <!--左边箭头 -->
                  <div v-if="item.Childs">
                    <a-icon type="right" v-show="!item.parentOpen" />
                    <a-icon type="down" v-show="item.parentOpen" />
                  </div>
                  <div v-else>
                    <a-icon type="right" />
                  </div>
                  <!-- end -->
                </div>
                <div class="sublevel">
                  <div
                    class="sublevel_list"
                    :class="sublevelAction == kmIndex ? 'sublevelBJ' : ''"
                    v-for="(itemKm, kmIndex) in item.Childs"
                    v-show="item.parentOpen"
                    @click="onSublevelOpen(itemKm, kmIndex, 'sublevel')"
                  >
                    {{ itemKm.KsbClassName
                    }}<span v-show="itemKm.AppNum != 0"
                      >({{ itemKm.AppNum }})</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="subject_box_right">
            <div class="subject_box_right_main">
              <ul class="subject_box_right_ul">
                <!-- 三级 -->
                <div
                  v-if="hierarchy == 3"
                  v-for="(item, index) in categoryRightData"
                  v-show="item.MediClassID != 0"
                >
                  <div class="KsbClassName_dh" @click="onCategoryData(index)">
                    <div class="KsbClassName_dh_text">
                      {{ item.KsbClassName }}
                    </div>
                    <a-icon
                      type="up"
                      v-show="!item.open"
                      :style="{ fontSize: '16px', color: '#676767' }"
                    />
                    <a-icon
                      type="down"
                      v-show="item.open"
                      :style="{ fontSize: '16px', color: '#676767' }"
                    />
                  </div>
                  <div class="subject_box_right_lb" v-show="item.open">
                    <li
                      class="subject_box_right_li"
                      @click="onSelectChilds(item, kmIndex)"
                      v-for="(itemKm, kmIndex) in item.Childs"
                    >
                      <span class="subject_box_right_li_text">{{
                        itemKm.KsbClassName
                      }}</span>
                      <span
                        class="subject_box_right_li_AppNumBox"
                        v-show="itemKm.AppNum != 0"
                        >(<span class="subject_box_right_li_AppNum">{{
                          itemKm.AppNum
                        }}</span
                        ><span>科目</span>)</span
                      >
                    </li>
                  </div>
                </div>
                <!-- 三级 end -->
                <!-- 科目 -->
                <div class="subject_box_right_top" v-if="hierarchy == 2">
                  <span @click="onCrumbs" class="getKsbClass_title">{{
                    seriesName
                  }}</span>
                  <span
                    v-show="KsbClassName != '' && !showSubject"
                    class="KsbClassName_text"
                    ><i class="el-icon-arrow-left" v-show="seriesName != ''"></i
                    >{{ KsbClassName }}</span
                  >
                  <span v-show="showSubject" class="KsbClassName_text"
                    ><i class="el-icon-arrow-left"></i>搜索</span
                  >
                </div>
                <li
                  class="subject_box_right_li_kemu"
                  v-if="hierarchy == 2"
                  v-for="(item, index) in KsbClassInfo"
                >
                  <div class="subject_box_right_li_kemu_left">
                    <span
                      class="subject_box_right_li_text"
                      v-html="item.Name"
                    ></span>
                    <!-- <span class="subject_box_right_li_AppNumBox"></span> -->
                  </div>
                  <div class="subject_box_right_li_kemu_questions">
                    <div class="subject_box_right_li_kemu_st">
                      {{ item.TestCount }}道试题
                    </div>
                  </div>
                  <div class="subject_box_right_li_kemu_course">
                    <div
                      class="subject_box_right_li_kemu_video"
                      @click="onPlayVideo(item)"
                    >
                      <i class="el-icon-video-play"></i>
                      <span>{{ item.KDJJ_Count }}节视频课</span>
                    </div>
                  </div>
                  <div class="subject_box_right_li_kemu_buy">
                    <div
                      class="subject_box_right_li_kemu_gm"
                      @click="onDetails(item)"
                    >
                      立即购买
                    </div>
                  </div>
                </li>
                <!-- 二级 end -->
              </ul>
              <div class="example" v-show="kmLoadingShow">
                <a-spin />
              </div>
            </div>
            <div
              class="notData"
              v-show="
                hierarchy == 2 && KsbClassInfo.length == 0 && !kmLoadingShow
              "
            >
              <img class="notData_img" src="../assets/img/noData.png" />
              <div class="notData_text">抱歉,未找到相应科目</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-box">
        <div class="footer-left">
          <div class="footer-left-L">
            <div>学习</div>
            <div>流程</div>
          </div>
          <div class="footer-left-R">
            <img src="../assets/img/Lark20210709-153412.png" />
          </div>
        </div>
        <div class="footer-right" @click="onCustomerService">
          <div class="footer-right-text">
            报名咨询热线<span class="footer-right-text-span"
              >(早8:00-晚22:00)</span
            >
          </div>
          <div class="contact">
            <div class="contact-left">
              <ul class="contact-left-ul">
                <li class="contact-left-li">微信&nbsp;602020500</li>
                <li class="contact-left-li">咨询&nbsp;4001856601</li>
                <li class="contact-left-li">售后&nbsp;4007278800</li>
              </ul>
            </div>
            <div class="contact-right">
              <img src="../assets/img/customer.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var that;

// @ is an alias to /src
import {
  getKsbClass,
  freeFegist,
  getCacheAppVnList,
  setTouristsInfo,
  updataTouristsInfo,
  getKsbClassInfo,
  videoCatalogue,
  videoPlayUrl,
  getSearch,
  getClassID,
  getAppKsbClass,
} from "../api/api.js";
import subject from "../components/subject.vue";
import floatingSidebar from "../components/floatingSidebar.vue";
export default {
  name: "Home",
  components: {
    subject,
    floatingSidebar,
  },
  data() {
    return {
      appID: 1682,
      buyList: [],
      tikulist: [],
      mianshen: [],
      sijiao: [],
      qita: [],
      jpkc: [],
      searchInput: "",
      getKsbClassData: [], //系列
      getKsbClassList: [], //类别
      getKsbClassTitle: "", //选择的系列标题
      active: "active", //是否选中
      hierarchy: 3, //用于判断是有三级还是二级
      KsbClassInfo: [], //科目
      KsbClassInfoTitle: "",
      KsbClassName: "", //系列名
      videoModalTrue: false,
      uniqueOpened: true, //是否只保持一个子菜单的展开
      videoList: [], // 视频列表
      videoSublevelList: [], //视频列表子级
      AppID: "", //AppID
      bookID: "",
      player: null, //视频播放器
      defaultActive: 0,
      KsbClassID: 0, //系列id
      KsbClassID2: 0, //类别id
      categoryData: [], //系列处理数据
      categoryRightData: [], //右边数据
      appPName: "",
      showSubject: false,
      hotSearch: [
        {
          MediClassID: 1,
          AppEName: "GJZC_FCKTX",
          AppID: 1141,
          Name: "妇产科学(副高)",
          xlKsbClassID: 151, //系列id
          KsbClassID: 189, //类别id
        },
        {
          MediClassID: 1,
          AppEName: "GJZC_LCHL_YTMJ",
          AppID: 1259,
          Name: "护理学(副高)",
          xlKsbClassID: 151, //系列id
          KsbClassID: 193, //类别id
        },
        {
          MediClassID: 1,
          AppEName: "GJZC_ZYNK_YTMJ",
          AppID: 1261,
          Name: "内科学(副高)",
          xlKsbClassID: 151, //系列id
          KsbClassID: 189, //类别id
        },
        {
          MediClassID: 1,
          AppEName: "GJZC_ZYNK_YTMJ",
          AppID: 1253,
          KsbClassID: 189,
          Name: "普通外科学(副高)",
          xlKsbClassID: 151, //系列id
          KsbClassID: 189, //类别id
        },
      ],
      HotSearchData: [], //热门搜索类别
      HotSearchSubject: [], //热门科目
      kmLoadingShow: false, //懒加载
      seriesName: "", //分类名
      medata: [],
      initialData: [], //保存还未处理的系类初始数据
      searchInitialData: [],
      searchLoading: false, //搜索
      childsCount: 3,
      sublevelAction: "null",
    };
  },
  created() {},
  mounted() {
    that = this;
    this.$parent.tabAvtion = 0; //tab选中
    this.onGetKsbClass(); //系列
    this.getFreeFegist(); //请求免费试用接口
  },
  methods: {
    //下载
    onDownload: function () {
      window.location.href =
        "http://agent-files.ksbao.com/100088-1/newksbao.exe";
    },
    //体验
    onExperience: function () {
      window.open("http://hzs.ksbao.com/?ac=100088-1");
    },
    //左边数据父级触发
    onParentOpen: function (item, index, incident) {
      that.getKsbClassList = item; //类别数据
      that.KsbClassID = item.KsbClassID; //类别id
      this.onLeftData(item, incident);
      this.sublevelAction = "null"; //初始化
    },
    //左边数据子级触发
    onSublevelOpen: function (item, index, incident) {
      this.sublevelAction = index;
      this.onLeftData(item, incident);
    },

    //父级子级事件触发调用方法
    onLeftData(item, incident) {
      var categoryData = this.categoryData; //左边数据
      this.showSubject = false; //是否搜索
      //父级展开收起
      if (incident == "parent") {
        for (let i in categoryData) {
          if (categoryData[i]["KsbClassName"] == item.KsbClassName) {
            categoryData[i]["parentOpen"] = !categoryData[i]["parentOpen"];
          } else {
            categoryData[i]["parentOpen"] = false;
          }
        }
      }
      console.log("hat.getKsbClassList", that.getKsbClassList);
      //
      if (item.Childs) {
        that.KsbClassID = item.KsbClassID; //系列 id
        that.seriesName = item.KsbClassName;
      } else {
        that.KsbClassName = item.KsbClassName; //类别名称
        that.KsbClassID2 = item.KsbClassID; //
        that.getKsbClassInfo(item.KsbClassID);
        //没触发子级事件就调用了科目接口，科目没有第三层级
        if (incident == "sublevel") {
          that.childsCount = 3; //用于传到详情页
        } else {
          that.childsCount = 2; //用于传到详情页
          that.seriesName = "";
        }

        that.hierarchy = 2; //显示对应内容
      }
    },

    //左边数据
    onCategoryData: function (index) {
      let categoryRightData = that.categoryRightData;
      for (let i in categoryRightData) {
        if (i == index) {
          categoryRightData[i]["open"] = !categoryRightData[i]["open"];
        }
      }
      that.categoryRightData = categoryRightData;
    },

    //类别
    onSelectChilds: function (item, index) {
      if (item.Childs[0].hierarchy == "two") {
        that.childsCount = 2; //用于传到详情页
      }
      that.getKsbClassList = item; //类别数据
      this.showSubject = false; //是否搜索
      that.seriesName = item.KsbClassName; //分类名
      that.KsbClassName = item.Childs[index].KsbClassName; //类别名称
      that.KsbClassID = item.KsbClassID; //系列 id
      that.KsbClassID2 = item.Childs[index].KsbClassID; //类别id
      that.getKsbClassInfo(item.Childs[index].KsbClassID);
      that.hierarchy = 2;
    },
    handleClose(key, keyPath) {
      // console.log('keykeykey',key, keyPath);
    },
    //热门搜索
    onHotSearch: function (item) {
      that.kmLoadingShow = true;
      //热门搜索需要的科目数据
      that.$subjectData((res) => {
        // that.HotSearchSubject = res.data.data;
        sessionStorage.setItem("subjectChoose", JSON.stringify(item)); //选择的科目
        let subjectData = {
          MediClassID: item.MediClassID,
          KsbClassID: item.xlKsbClassID, //系列id
          KsbClass: that.HotSearchData, //类别数组
          KsbClassID2: item.KsbClassID, //类别id
          KsbClassInfo: item.KsbClassID == 189 ? res[0] : res[1], //科目数组
          appPName: item.Name, //选择的科目
        };
        that.kmLoadingShow = false;
        var active = {
          active: 0,
        };
        that.$router.push({
          path: "/details/" + item.AppEName,
          // query: active
        });
        sessionStorage.setItem("subjectData", JSON.stringify(subjectData));
        sessionStorage.setItem("subjectCache", JSON.stringify(subjectData));
      });
    },
    onClose: function () {
      that.appPName = "";
    },
    //搜索
    onClickSearch: function () {
      let data = {
        appPName: that.appPName,
      };
      if (that.appPName == "") {
        this.$message.info("请输入搜索科目或代码!");
        return;
      }
      //
      if (that.appPName != "") {
        that.showSubject = true;
      }
      that.searchLoading = true;
      getSearch(data).then((res) => {
        //没有搜到科目
        if (res.data.data.length == 0) {
          that.KsbClassInfo = [];
        }

        that.hierarchy = 2;
        var restut = JSON.stringify(res.data.data); //转为字符串
        that.searchInitialData = res.data.data;
        let searchBox = new RegExp("(" + that.appPName + ")", "g"); //正则查找并匹配搜索内容
        let list = JSON.parse(
          restut.replace(
            searchBox,
            "<span style='color: #56c2de;'>" + that.appPName + "</span>"
          )
        ); //匹配到内容改变字体颜色

        // that.KsbClassInfo = res.data.data;
        that.onGetTestNumData(list);
        that.searchLoading = false;
      });
    },

    // 跳转到详情页
    onDetails(item) {
      var restut = JSON.stringify(item); //转为字符串
      var text = restut.replace(
        RegExp("<span style='color: #56c2de;'>", "g"),
        ""
      ); //匹配内容
      var textA = text.replace(RegExp("</span>", "g"), ""); //匹配内容
      sessionStorage.setItem("subjectChoose", JSON.stringify(item)); //选择的科目
      //搜索
      if (that.showSubject) {
        that.onSubjecCategory(
          JSON.parse(textA).AppEName,
          JSON.parse(textA).Name,
          item
        );
        return;
      }

      let subjectData = {
        MediClassID: this.getKsbClassList.MediClassID,
        KsbClassID: this.KsbClassID, //系列id
        KsbClass: this.getKsbClassList.Childs, //类别数组
        KsbClassID2: this.KsbClassID2, //类别id
        KsbClassInfo: this.KsbClassInfo, //科目数组
        appPName: JSON.parse(textA).Name, //选择的科目
        Childs: this.childsCount, //传到详情页
      };
      // var active = {
      // 	active: 0
      // };

      console.log("购买参数", subjectData);
      this.$router.push({
        path: "/details/" + item.AppEName,
        // query: active
      });
      sessionStorage.setItem("subjectData", JSON.stringify(subjectData));
      sessionStorage.setItem("subjectCache", JSON.stringify(subjectData));
    },
    //处理搜索科目归属上级的类别
    onSubjecCategory(AppEName, Name, item) {
      let subjectList = JSON.parse(sessionStorage.getItem("resltu")).datas;
      let data = {
        SessionKey: "1C79CE6C3037EA1594CEA4CE3653C08B",
        appEName: AppEName,
      };
      getAppKsbClass(data).then((res) => {
        var simpc = res.data.data[0].KsbClassName;
        var simpclass = simpc.substr(0, simpc.indexOf("_"));
        // || simpclass=='副高' || simpclass=='初级' || simpclass=='中级'
        var KsbClass = []; //类别数组
        var KsbClassID = ""; //系列id
        var KsbClassID2 = ""; //类别id
        var MediClassID = "";
        var hierarchy = 3; //层级
        for (let i in subjectList) {
          if (subjectList[i].KsbClassName == simpclass) {
            console.log("subjectList[i]", subjectList[i]);
            if (subjectList[i].Childs) {
              KsbClass = subjectList[i].Childs;
              KsbClassID2 = subjectList[i].Childs.KsbClassID; //类别id
            } else {
              hierarchy = 2;
              console.log("subjectList[i]", subjectList[i]);
            }
            MediClassID = subjectList[i].MediClassID;
            KsbClassID = subjectList[i].KsbClassID; //系列id
          }
        }

        let subjectData = {
          MediClassID: MediClassID,
          KsbClassID: KsbClassID, //系列id
          KsbClass: KsbClass, //类别数组
          KsbClassID2: item.KsbClassID, //类别id
          KsbClassInfo: this.searchInitialData, //科目数组
          Childs: hierarchy,
          appPName: Name, //选择的科目
        };
        console.log("subjectData", subjectData);
        sessionStorage.setItem("subjectData", JSON.stringify(subjectData));
        sessionStorage.setItem("subjectCache", JSON.stringify(subjectData));
        var active = {
          active: 0,
        };
        this.$router.push({
          path: "/details/" + AppEName,
          // query: active
        });
      });
    },

    //播放视频
    onPlayVideo: function (item) {
      if (item.KDJJ_Count == 0) {
        this.$message.info("该科目暂无视频!");
        return;
      }

      window.open(
        `http://hzstg.ksbao.com/freeclasspc/#/?slbs=100088-1&slbs2=&source=武汉神州PC&item=${JSON.stringify(
          item
        )}`
      );

      // this.AppID = item.AppID;
      // this.videoModalTrue = true;
      // this.KsbClassInfoTitle = item.Name;
      // setTimeout(() => {
      // 	that.onPlay();
      // 	that.onVideoCatalogue(item.AppID);
      // }, 1000)
    },
    //退出
    onCancelModal() {
      that.player.dispose(); //销毁
      that.videoList = []; // 视频列表
      that.videoSublevelList = []; //视频列表子级
      (that.AppID = ""), //AppID
        (that.bookID = ""),
        (that.defaultActive = 0);
    },
    // onVideoClose:function(){
    // 	// console.log('退出退出退出退出退出')
    // 	// that.player.dispose(); //销毁
    // },
    onPlay(source) {
      that.player = new Aliplayer(
        {
          id: "J_prismPlayer",
          width: "700",
          height: "500px",
          autoplay: true,
          source: source,
        },
        function (player) {}
      );
      console.log("8888888", that.player);
    },
    //面包屑
    onCrumbs: function () {
      this.showSubject = false; //是否搜索
      var getks = new Array();
      getks.push(this.getKsbClassList);
      console.log("getks", getks);
      that.categoryRightData = getks;
      that.hierarchy = 3;
    },

    //系列
    async onGetKsbClass() {
      //数据处理
      if (JSON.parse(sessionStorage.getItem("resltu"))) {
        let resltu = JSON.parse(sessionStorage.getItem("resltu"));
        that.categoryData = resltu.datas; //系列左边数据

        //重构数据
        that.categoryData = that.categoryLeftData(that.categoryData);

        //右边数据用于数据交互处理
        that.categoryRightData = resltu.data;

        //右边数据用于数据交互处理，需要重构数据时候打开
        // that.categoryRightData =  that.categoryLeftData(resltu.data);
        that.onHotSearchData(resltu.datas);
        return;
      }
      let data = {
        agentID: "100088-1",
        isNewMedi: 1,
      };
      that.kmLoadingShow = true;
      await getKsbClass(data).then((res) => {
        that.medata = res.data.medata;
        // this.getKsbClassData = resltu; //系列

        // this.getKsbClassList = resltu[0].Childs; //类别
        // this.KsbClassID = resltu[0].KsbClassID; //系列id
        this.active = 0; //系列下标
        that.onSort(res.data.data, (res) => {
          for (let j in res) {
            res[j]["open"] = true; //展开默认为true
            res[j]["parentOpen"] = false; //
          }

          var resltu = res;
          that.onHotSearchData(resltu);
          this.onRightCategoryData(
            JSON.parse(JSON.stringify(resltu)),
            JSON.parse(JSON.stringify(res))
          );
          that.kmLoadingShow = false;
        });
      });
    },
    //重构数据,用于增加医疗招聘的项目
    categoryLeftData(data) {
      var result = [];
      var zhaopinglei = {
        AppNum: 0,
        CName: "医疗招聘",
        IsMedi: 1,
        KsbClassID: 999,
        KsbClassName: "医疗招聘",
        MediClassID: 4,
        PID: -1,
        SName: "",
        index: 1,
        open: true,
        Childs: [],
        parentOpen: false,
      };

      //包含这些分类的班级ID ，进行重组到招聘里面
      var zhaopinleiKsbClassIDs = [377, 378, 379, 380, 381, 382, 383, 384, 385];

      //放进招聘里面
      for (var i = 0; i < data.length; i++) {
        //如果是,就往里面添加
        if (zhaopinleiKsbClassIDs.indexOf(data[i].KsbClassID) != -1) {
          zhaopinglei.Childs.push(data[i]);
        }
      }

      //放在药师下面
      for (var i = 0; i < data.length; i++) {
        if (zhaopinleiKsbClassIDs.indexOf(data[i].KsbClassID) == -1) {
          result.push(data[i]);
          if (data[i].KsbClassID == 156) {
            result.push(zhaopinglei);
          }
        }
      }

      return result;
    },
    onHotSearchData(resltu) {
      for (let i in resltu) {
        if (resltu[i].KsbClassName == "副高卫生职称") {
          that.HotSearchData = resltu[i].Childs; //热门搜索类别
        }
      }
    },
    //获取目录-通过分类获取具体科目
    getKsbClassInfo(KsbClassID) {
      that.kmLoadingShow = true;
      var KsbClassID = {
        KsbClassID: KsbClassID,
        agentID: "100088-1",
        // SessionKey: '83B274D55E82E918950BE60B7F81C02D'
      };
      getKsbClassInfo(KsbClassID).then((res) => {
        if (res.data.status == 200) {
          //区分是选择科目还是热门搜索科目
          that.KsbClassInfo = res.data.data;
          that.onGetTestNumData(res.data.data);
          that.kmLoadingShow = false;
        } else {
          return false;
        }
      });
    },
    //处理右边数据
    onRightCategoryData(resltu, resltus) {
      // for (let j in resltus) {
      // 	resltus[j]. ['open'] = true; //展开默认为true
      // }

      var KsbClassID = {};
      for (let i in resltu) {
        KsbClassID = {
          KsbClassID: resltu[i].KsbClassID,
          agentID: "100088-1",
          // SessionKey: '83B274D55E82E918950BE60B7F81C02D'
        };

        // resltu[i]. ['open'] = true; //展开默认为true
        if (resltu[i].Childs == null) {
          getKsbClassInfo(KsbClassID).then((res) => {
            if (res.data.status == 200) {
              // that.KsbClassInfo = res.data.data;
              // that.onGetTestNumData(res.data.data)

              for (let j in res.data.data) {
                if (res.data.data[j].KsbClassID == resltu[i].KsbClassID) {
                  res.data.data[j]["KsbClassName"] = res.data.data[j].Name; //科目民
                  res.data.data[j]["AppNum"] = 0;
                  res.data.data[j]["hierarchy"] = "two"; //三级
                  resltu[i].Childs = res.data.data;
                }
              }

              let da = {
                medata: that.medata,
                data: resltu,
                datas: resltus, //没有处理的数据
              };
              sessionStorage.setItem("resltu", JSON.stringify(da));
            } else {
              return false;
            }
          });
        } else {
          for (let j in resltu[i].Childs) {
            resltu[i].Childs[j]["hierarchy"] = "three"; //三级
          }
        }
      }
      // this.categoryData = resltus; //系列数据

      that.categoryData = that.categoryLeftData(resltus);
      this.categoryRightData = resltu; //右边数据用于数据交互处理
    },

    //排序
    onSort(subjectList, callback) {
      for (let i in subjectList) {
        if (subjectList[i].KsbClassName == "正高卫生职称") {
          subjectList[i]["index"] = 1;
        } else if (subjectList[i].KsbClassName == "副高卫生职称") {
          subjectList[i]["index"] = 2;
        } else if (subjectList[i].KsbClassName == "中级卫生职称") {
          subjectList[i]["index"] = 3;
        } else if (subjectList[i].KsbClassName == "初级卫生职称") {
          subjectList[i]["index"] = 4;
        } else {
          subjectList[i]["index"] = 5;
        }
      }

      subjectList.sort(function (a, b) {
        return a.index - b.index;
      });
      callback(subjectList);
    },

    //获取试题和课程数
    onGetTestNumData(KsbClassInfo) {
      console.log("KsbClassInfoKsbClassInfo", KsbClassInfo);
      var urls = "";
      for (let i in KsbClassInfo) {
        urls = `http://116.62.79.97/mypro?url=http://10.94.183.7:9013/api/tkcore/getTestNum?SessionKey=1C79CE6C3037EA1594CEA4CE3653C08B&appEName=${KsbClassInfo[i].AppEName}`;
        this.axios({
          url: urls,
        }).then((res) => {
          if (res.data.data[0].AppEName == KsbClassInfo[i].AppEName) {
            KsbClassInfo[i]["KDJJ_Count"] = res.data.data[0].KDJJ_Count; //课程数
            KsbClassInfo[i]["TestCount"] = res.data.data[0].TestCount; //试题
            KsbClassInfo[i]["YTMJTestCount"] = res.data.data[0].YTMJTestCount;
            that.KsbClassInfo = KsbClassInfo;
            that.$forceUpdate();
          }
        });
      }
    },
    onVideoOpen(bookID) {
      this.bookID = bookID;
      this.onVideoCatalogueSublevel(bookID, "on");
    },
    //考点精讲
    onVideoCatalogue(AppID) {
      let data = {
        appID: AppID,
        guid: JSON.parse(sessionStorage.getItem("loginData")).guid,
        type: 1,
        hierarchy: 0,
      };
      videoCatalogue(data).then((res) => {
        that.videoList = res.data.data;
        that.bookID = res.data.data[0].bookID; //默认第一个bookID
        that.onVideoCatalogueSublevel(res.data.data[0].bookID, "load");
      });
    },
    //考点精讲第二层
    onVideoCatalogueSublevel(bookID, behavior) {
      let data = {
        appID: this.AppID,
        guid: JSON.parse(sessionStorage.getItem("loginData")).guid,
        type: 1,
        hierarchy: 1,
        bookID: bookID,
      };
      videoCatalogue(data).then((res) => {
        that.videoSublevelList = res.data.data.childs;
        //打开模态了执行
        if (behavior == "load") {
          that.defaultActive = JSON.stringify(
            res.data.data.childs[0].names[0].id
          );
          that.onVideoPlayUrl(res.data.data.childs[0].names[0].id);
        }
        // console.log('that.defaultActive',that.defaultActive)
      });
    },
    onVideoSelect: function (videoID) {
      that.player.dispose(); //销毁
      console.log("videoPlayUrl", videoID);
      this.onVideoPlayUrl(videoID);
    },
    onVideoPlayUrl(videoID) {
      let data = {
        agentID: 889,
        appID: this.AppID,
        guid: JSON.parse(sessionStorage.getItem("loginData")).guid,
        videoID: videoID,
        src: 4,
        type: 1,
        bookID: this.bookID,
      };
      videoPlayUrl(data).then((res) => {
        that.onPlay(
          res.data.data.fileUrls[1].fileUrl +
            "?MtsHlsUriToken=" +
            res.data.data.token
        );
      });
    },
    //底部客服
    onCustomerService: function () {
      window.open(
        "http://p.qiao.baidu.com/cps/chat?siteId=6100319&userId=7778666"
      );
    },
  },
};
</script>
<style scoped>
/* .box .el-menu-item.is-active {
	  background: #6db6ff !important;
	}
	.box .el-submenu__title.is-active {
	  background: #6db6ff !important;
	} */
.box >>> .el-submenu__title {
  position: relative;
}

/* //菜单关闭 */
.boxs >>> .el-submenu__icon-arrow {
  -webkit-transform: rotateZ(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotateZ(-90deg);
}

/* //菜单展开 */
.boxs >>> .el-submenu__icon-arrow {
  -webkit-transform: rotateZ(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotateZ(-90deg);
}

/* WebKit browsers */
input::-webkit-input-placeholder {
  color: #999999 !important;
}

/* / Mozilla Firefox 4 to 18 / */
input:-moz-placeholder {
  color: #999999 !important;
}

/* / Mozilla Firefox 19+ / */
input::-moz-placeholder {
  color: #999999 !important;
}

/* / Internet Explorer 10+ */
input:-ms-input-placeholder {
  color: #999999 !important;
}

body,
ul,
li {
  margin-bottom: 0px;
}

.KsbClassInfoTitle {
  border-bottom: 1px solid #eeeeee;
}

.KsbClassName_dh {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
  width: 100%;
  height: 57px;
  color: #1d1d1d;
  border-bottom: 1px solid #e6e6e6;
  box-sizing: border-box;
}

/* .qrCode-box {
		width: 300px;
		height: 350px;
		position: absolute;
		right: 50px;
		top: 50%;
		margin-top: -175px;
		z-index: 999;
		background: #FFFFFF;
		overflow: hidden;
	}

	.qrCode-box .qrCode-img {
		width: 250px;
		height: 250px;
		margin-top: 25px;
		margin-left: 25px;
	}

	.qrCode-box .qrCode-text {
		width: 300px;
		height: 50px;
		margin-top: 10px;
		text-align: center;
	} */
.qrCode-box {
  width: 240px;
  height: 300px;
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -150px;
  z-index: 999;
  background: #ffffff;
  overflow: hidden;
}

.qrCode-box .qrCode-img {
  width: 190px;
  height: 200px;
  margin-top: 25px;
  margin-left: 25px;
}

.qrCode-box .qrCode-text {
  width: 240px;
  height: 50px;
  margin-top: 10px;
  text-align: center;
}

.qrCode-text-one {
  font-size: 18px;
  color: #f76a09;
}

.qrCode-text-two {
  color: #f76a09;
}

.activeBackground {
  background: #1890ff !important;
}

.subject_headings {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
}

.subject_headings_left {
  display: flex;
  align-items: center;
  width: 20%;
  height: 30px;
  padding-left: 10px;
  box-sizing: border-box;
}

.subject_headings_left-line {
  display: inline-block;
  width: 5px;
  height: 25px;
  opacity: 1;
  background: #62befc;
}

.subject_headings_left-text {
  margin-left: 12px;
  font-weight: 500;
  font-size: 20px;
  color: #1d1d1d;
}

.subject_headings_center {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  height: 30px;
}

.subject_headings_center_ul {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.subject_headings_center_span {
  font-size: 18px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  color: #1d1d1d;
}

.subject_headings_center_li {
  color: #999999;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.subject_headings_right {
  display: flex;
  flex-direction: row;
  width: 30%;
  height: 30px;
}

.search_input-box {
  width: 253px;
  height: 38px;
  color: #888888;
  font-size: 16px;
  position: relative;
}

.search_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 38px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.close-circle {
  position: absolute;
  right: 10px;
  top: 10px;
}

.search_input {
  width: 253px;
  height: 38px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  color: #000000;
  border-radius: 4px;
  padding-left: 30px;
  box-sizing: border-box;
}

.search_input_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 94px;
  height: 38px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #62befc;
  color: #62befc;
  border-radius: 4px;
  margin-left: 20px;
}

.subject_box {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 600px;
}

.subject_box_left {
  width: 20%;
  height: 100%;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  overflow: scroll;
}

.subject_box_li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin-bottom: 3px;
  padding: 0px 15px;
  background: #f9f9f9;
  color: #000000;
  box-sizing: border-box;
}

.subject_box_ul_name {
  display: flex;
  align-items: center;
  color: #676767;
  font-size: 14px;
  height: 50px;
  padding-left: 40px;
  cursor: pointer;
  box-sizing: border-box;
}

/* 修改滚动条样式 */
.subject_box_left::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /**/
}

.subject_box_left::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}

.subject_box_left::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}

.subject_box_left::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.subject_box_left::-webkit-scrollbar-corner {
  background: #eeeeee;
}

/* .subject_box_left::-webkit-scrollbar {
		display: none;
	}
 */
.subject_box_right {
  width: 80%;
  height: 100%;
  overflow: scroll;
}

.subject_box_right::-webkit-scrollbar {
  display: none;
}

.subject_box_right_top {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  padding-left: 20px;
  background: #def2ff;
  cursor: pointer;
  box-sizing: border-box;
}

.subject_box_right_ul {
  /* display: flex;
		flex-wrap: wrap;
		flex-direction: row; */
}

.subject_box_right_li {
  padding: 2px 10px;
  font-size: 15px;
  margin-left: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #62befc;
  border-radius: 18px;
  cursor: pointer;
  color: #62befc;
  cursor: pointer;
}

.subject_box_right_lb {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 14px;
}

.subject_box_right_li_AppNum {
  color: #ffcb81;
}

.subject_box_right_li_kemu {
  display: flex;
  align-items: center;
  padding-left: 30px;
  width: 100%;
  height: 63px;
  color: #000000;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
}

.subject_box_right_li_kemu_left {
  width: 45%;
}

.subject_box_right_li_kemu_questions {
  width: 25%;
}

.subject_box_right_li_kemu_st {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666a6f;
  width: 130px;
  height: 34px;
  opacity: 1;
  background: #def5ff;
  border-radius: 5px;
  cursor: pointer;
}

.subject_box_right_li_kemu_course {
  width: 30%;
}

.subject_box_right_li_kemu_video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 40px;
  opacity: 1;
  border: 1px solid #62befc;
  color: #62befc;
  border-radius: 5px;
  cursor: pointer;
}

.subject_box_right_li_kemu_buy {
  width: 20%;
}

.subject_box_right_li_kemu_gm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 42px;
  opacity: 1;
  background: #ffbd5e;
  color: #ffffff;
  border: 1px solid #ffbd5e;
  border-radius: 5px;
  cursor: pointer;
}

.KsbClassName_text {
  color: #1d1d1d;
}

.getKsbClass_title {
  color: #676767;
}

.el-menu-vertical-demo-box {
  overflow: scroll;
  height: 500px;
}

.el-menu-vertical-demo-box::-webkit-scrollbar {
  display: none;
}

.video-box {
  height: 500px;
}

/* 底 */
.footer {
  width: 100%;
  height: 200px;
  margin-top: 39px;
  margin-bottom: 48px;
  /* background: #e3f6ff; */
}

.footer-box {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  height: 200px;
  margin: 0 auto;
}

.footer-left {
  display: flex;
  flex-direction: row;
  width: 879px;
  height: 100%;
}

.footer-right {
  width: 303px;
  height: 100%;
  cursor: pointer;
  background: #e3f6ff;
}

.footer-right-text {
  display: flex;
  align-items: center;
  opacity: 1;
  font-size: 22px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #1d1d1d;
  margin-top: 24px;
  margin-left: 20px;
  letter-spacing: 2px;
}

.footer-left-L {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 148px;
  height: 100%;
  opacity: 1;
  background: #5eccff;
}

.footer-left-L div {
  font-size: 36px;
  color: #ffffff;
}

.footer-left-R {
  width: 731px;
  height: 100%;
  background: #e3f6ff;
}

.contact {
  display: flex;
  flex-direction: row;
}

.contact-left {
  width: 180px;
  height: 100%;
  margin-top: 25px;
}

.contact-left-li {
  color: #ffa91e;
  font-size: 20px;
  margin-left: 20px;
}

.contact-right {
  width: 122px;
  height: 100%;
  margin-top: 15px;
}

.contact-right img {
  width: 100%;
}

/* //轮播图 */

/* For demo */
.banner-img {
  width: 100%;
  height: 407px;
  margin: 0 auto;
}

.banner-img img {
  width: 100%;
  height: 407px;
}

.ant-carousel-box {
  /* margin-bottom: 43px; */
  position: relative;
}

.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 407px;
  line-height: 407px;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}

.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}

.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}

.ant-carousel >>> .ant-carousel .slick-dots {
  height: 22px;
}

.ant-carousel >>> .ant-carousel .slick-dots li {
  margin: 0 6px;
}

.ant-carousel >>> .ant-carousel .slick-dots li button {
  width: 11px;
  height: 11px;
  border: 1px solid #ffffff;
  border-radius: 11px;
  /* margin-right: 12px; */
}

.example {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
</style>
<style scoped lang="less">
.box .public {
  width: 100%;
}

.tiku {
  width: 200px;
  height: 30px;
  border-left: 3px solid #62befc;
  color: #62befc;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  padding-left: 10px;
  margin-top: 30px;
}

.kecheng {
  width: 200px;
  height: 30px;
  border-left: 3px solid #62befc;
  color: #62befc;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  padding-left: 10px;
}

.fenlei {
  //   height: 900px;
  background-color: #fff;
  margin-top: 20px;
}

.typepage {
  width: 1220px;
  height: 100%;
  //   background-color:red;
  margin: 0 auto;
  // padding-left: 25px;
  // padding-right: 25px;
  // padding-bottom: 25px;
  margin-bottom: 10px;
  overflow: hidden;
}

.aa {
  margin-top: 47px;
  font-size: 37px;
}

.banci {
  width: 375px;
  height: 250px;
  background-color: #ffffff;
  overflow: hidden;
  float: left;
  margin-right: 14px;
  margin-top: 30px;
  cursor: pointer;
}

.bancitk {
  width: 375px;
  height: 250px;
  background-color: #ffffff;
  overflow: hidden;
  float: left;
  margin-right: 14px;
  margin-top: 30px;
  cursor: pointer;
}

.ww {
  width: 375px;
  height: 278px;
  // margin-left: 30px;
  overflow: hidden;
  // opacity: 0.7;

  float: left;
  margin-right: 14px;
  margin-top: 30px;
}

.wwa {
  width: 100%;
  height: 195px;
  padding: 20px 0px 0px 0px;
  box-sizing: border-box;
  background: #ddf0fd;

  background: url(../assets/img/tk.png);
  background-size: cover;
}

.ww:hover {
  border: 2px solid red;
}

.bancibgtk {
  width: 100%;
  height: 195px;
  background-image: url(../assets/img/tk.png);
  background-size: 100%;
}

.bancibgjpkc {
  width: 100%;
  height: 195px;
  background-image: url(../assets/img/jpkc.png);
  background-size: 100%;
}

.bancibgms {
  width: 100%;
  height: 195px;
  background-image: url(../assets/img/ms.png);
  background-size: 100%;
}

.bancibgzssj {
  width: 100%;
  height: 195px;
  background-image: url(../assets/img/zssj.png);
  background-size: 100%;
}

.bancibgqt {
  width: 100%;
  height: 195px;
  background-image: url(../assets/img/qt.png);
  background-size: 100%;
}

.bancibottom {
  width: 280px;
  height: 83px;
  // background-color: #fff000;
  float: left;
}

// .banci:hover {
// 	border: 2px solid #62befc;
// }

// .bancitk:hover {
// 	border: 2px solid #62befc;
// }

.title {
  width: 375px;
  font-size: 20px;
  font-family: Source Han Sans CN Regular;
  text-align: center;
  font-weight: 400;
  padding-top: 15px;
  // background-color: #0000FF;
  color: #333333;
}

.line-a {
  width: 335px;
  height: 1px;
  background-color: #fff;
  margin-left: 20px;
  margin-top: 10px;
}

.line-b {
  width: 335px;
  height: 1px;
  background-color: #e0e0e0;
  margin-left: 20px;
  margin-top: 16px;
}

.banci ul {
  list-style-type: none;
  padding: 0;
  margin-left: 30px;
}

.banci ul li {
  margin-top: 40px;
}

.bancitk ul {
  list-style-type: none;
  padding: 0;
  margin-left: 30px;
}

.bancitk ul li {
  margin-top: 40px;
}

.banci-a {
  width: 250px;
  height: 16px;
  font-weight: Normal;
  font-family: Source Han Sans CN Regular;
  font-size: 16px;
  color: #333333;
}

.banci-b {
  // width: 300px;
  // height: 14px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333333;
  font-size: 14px;
}

.banci-c {
  // width: 350px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;

  font-size: 14px;
}

.banci-d {
  width: 380px;
  height: 14px;
  font-size: 14px;
  list-style-type: none !important;
}

.banci-c-img {
  float: left;
  margin-top: 3px;
}

.banci-c-text {
  float: left;
  overflow: hidden;
  height: 30px;
  color: #000000;
  margin-right: 10px;
  background: #fff;
  opacity: 0.7;
  padding-left: 5px;
  padding-right: 5px;
  // background: #eef8fe;
}

.banci-d-img {
  float: left;
  margin-top: 3px;
  margin-right: 5px;
}

.banci-d-text {
  float: left;
  color: #666a6f;
}

.banci-d-buy {
  color: #666a6f;
  margin-left: 100px;
  font-weight: bold;
}

.banci-e-buy {
  color: #ea4335;
  margin-left: 100px;
  font-weight: bold;
}

.huo {
  float: left;
  margin-left: 20px;
  margin-top: 20px;
}

.VerName {
  width: 375px;
  height: 42px;
  font-size: 20px;
  line-height: 42px;
  font-size: 500;
}

.Content {
  max-width: 270px;
  height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #5d5d5d;
  float: left;
  margin-left: 10px;
}

.bottom {
  width: 365px;
  height: 42px;
  line-height: 42px;
  // background-color: bisque;
}

.price {
  width: 80px;
  height: 83px;
  font-size: 18px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  float: left;
  text-align: right;
  color: #ea4335;

  // background-color: #00F7DE;
  // padding-left: 270px;
}

.notData {
  text-align: center;
  margin-top: 200px;
}

.notData_img {
  width: 100px;
  height: 100px;
}

.notData_text {
  color: #9f9f9f;
  margin: 20px;
}

.search_load {
  display: inline-block;
  margin-right: 3px;
}

// -------------
.download_box {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 500px;
  // margin-left:-300px;
  bottom: 20px;
}

.download_btn {
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;
  width: 152px;
  height: 38px;
  opacity: 1;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 16px;
  border-radius: 5px;
  margin-left: 24px;
  cursor: pointer;
}

.experience_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 152px;
  height: 38px;
  opacity: 1;
  font-size: 16px;
  color: #4cb6fc;
  background: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}

// -----------------
.box_left_parent_list {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  opacity: 1;
  font-size: 16px;
  color: #676767;
  border-bottom: solid 1px #e6e6e6;
  cursor: pointer;
  box-sizing: border-box;
}

.box_left_parent_span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 44px;
  width: 100%;
  min-height: 44px;
  padding: 0px 15px;
  cursor: pointer;
  box-sizing: border-box;
}

.sublevel {
  width: 100%;
}

.sublevel_list {
  width: 100%;
  height: 38px;
  line-height: 38px;
  color: #676767;
  font-size: 14px;
  opacity: 1;
  background: #f5fbff;
  padding: 0px 15px;
  cursor: pointer;
  box-sizing: border-box;
}

.parentAvtion {
  background: #def2ff;
  color: #47b5ff;
}

.sublevelBJ {
  background: #def2ff;
  color: #47b5ff;
}
.footer-right-text-span {
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #8c8c8c;
}
</style>
