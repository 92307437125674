<template>
  <div class="box">
    <div class="btn-o">
      <div class="btn">
        <div class="uuid">
          <div
            class="tabs"
            v-for="(item, index) in list"
            :key="index"
            @click.stop.prevent="Submit(index)"
            :class="{ active: morenIndex == index }"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="page-o">
      <div id="page0" style="background:red;"></div>
      <div id="page1" style="background:blue;"></div>
      <div id="page2" style="background:skyblue;"></div>
      <div id="page3" style="background:pink;"></div>
      <div id="page4" style="background:green;"></div>
      <div id="page5" style="background:black;"></div>
      <div id="page6" style="background:yellow;"></div>
      <div id="page7" style="background:purple;"></div>
      <div id="page8" style="background:orange;"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      morenIndex: 0,
      scrollY: null,
      divHeight: null,
      list: [
        { name: "按钮0" },
        { name: "按钮1" },
        { name: "按钮2" },
        { name: "按钮3" },
        { name: "按钮4" },
        { name: "按钮5" },
        { name: "按钮6" },
        { name: "按钮7" },
        { name: "按钮8" }
      ]
    };
  },
  watch: {
    scrollY(val) {
      console.log(val, "val11");
      if (val - 40 === 300) {
        alert("300临界点");
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    Submit(key) {
      this.morenIndex = key;
      // 获取点击的按钮对应页面的id
      var PageId = document.querySelector("#page" + key);

      // 打印出对应页面与窗口的距离
      //   console.log(PageId)
      //   console.log(PageId.offsetTop)
      // 使用平滑属性，滑动到上方获取的距离
      // 下方我只设置了top，当然 你也可以加上 left 让他横向滑动
      // widow 根据浏览器滚动条，如果你是要在某个盒子里面产生滑动，记得修改
      window.scrollTo({
        top: PageId.offsetTop - 40,
        // left: PageId.offsetLeft,
        behavior: "smooth"
      });
    },
    handleScroll() {
      this.scrollY = document.documentElement.scrollTop + 40;
      console.log(this.scrollY, "this.scrollY");
    },
    // 获取每个颜色区域对应的高度
    getDivHeight() {}
  }
};
</script>

<style scoped>
.box {
  padding-top: 40px;
}
.page-o {
  width: 10%;
}
.page-o div {
  width: 10%;
  height: 300px;
}
.btn-o {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 10%;
  height: 40px;
  overflow: hidden;
  background: #ffffff;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
}
.btn {
  overflow-x: scroll;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ededed;
}
.btn::-webkit-scrollbar {
  display: none;
}
.uuid {
  display: -webkit-box;
}
.tabs {
  margin-left: 26px;
  font-size: 14px;
  font-weight: 500;
  color: #798089;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.active {
  color: #000 !important;
  font-weight: 800;
  font-size: 16px;
}
</style>