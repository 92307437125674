<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  font-size: 16px;
}

.yidun_intellisense--light .yidun_intelli-icon .yidun_logo {
  background: #fff !important;
}

.yidun_intellisense--light .yidun_intelli-control {
  position: relative;
  height: 46px !important;
  font-size: 14px;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #e4e7eb;
  background-color: #f7f9fa;
  overflow: hidden;
  width: 28%;
  top: -46px;
  left: 332px;
}

.yidun_intellisense--light .yidun_intelli-icon {
  position: relative;
  margin-right: 2px;
  width: 0px !important;
  height: 0px !important;
  vertical-align: middle;
  border-radius: 0;
  background-color: #fff;
  box-shadow: 0 2px 8px 1px rgba(188, 196, 204, 0.5);
  transition: all 0.2s linear;
  padding: 0;
}

.yidun_intellisense--light .yidun_intelli-tips {
  color: #4faffe;
}

.yidun_intellisense--light .yidun_intelli-text {
  color: #4faffe;
}

.yidun_intellisense--light .yidun_intelli-control {
  font-size: 14px;
  border: 1px solid #d9d9d9 !important;
  background-color: #fff !important;
  padding: 0;
}

.yidun_intellisense--light.yidun_intellisense--success .yidun_classic-tips {
  /* color: #52ccba; */
  background-color: #fff;
}

#nav {
  // padding: 30px;

  // a {
  //   font-weight: bold;
  //   color: #2c3e50;

  //   &.router-link-exact-active {
  //     color: #42b983;
  //   }
  // }
}
</style>
<script>
export default {
  name: "App",
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true, //控制视图是否显示的变量
    };
  },
  created() {
    // 获取终端的相关信息
    var Terminal = {
      // 辨别移动终端类型
      platform: (function () {
        var u = navigator.userAgent,
          app = navigator.appVersion;
        console.log("uuuuuu", u);
        return {
          // android终端或者uc浏览器
          android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1,
          // 是否为iPhone或者QQHD浏览器
          iPhone: u.indexOf("iPhone") > -1,
        };
      })(),
    };
    // 根据不同的终端，跳转到不同的地址
    var theUrl = "/";
    if (Terminal.platform.android) {
      theUrl = "/phone/";
      location.href = theUrl;
    } else if (Terminal.platform.iPhone) {
      theUrl = "/phone/";
      location.href = theUrl;
    }
  },

  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>