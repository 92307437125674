import {
	freeFegist
} from "../api/api.js";

async function getFreeFegist() {
	//判断用户是否有登录 cellphone；
	if (sessionStorage.getItem("cellphone")) {
		console.log('已登录')
	} else {
		//没有登录执行到这一步，请求免费试用接口
		let data = {
			agentCode: "100088-1",
			clientType: "武汉神州-网校版"
		}
		//没有登录请求免费试用接口，并判断缓存是否有免费试用的guid信息，没有请求接口，有从缓存获取
		if (sessionStorage.getItem('freeFegistData')) {
			//获取缓存,储存在loginData
			sessionStorage.setItem("loginData", sessionStorage.getItem('freeFegistData'));
			sessionStorage.setItem("logging", 0);

		} else {
			//请求接口
			await freeFegist(data).then(res => {
				if (res.data.status == 200) {
					sessionStorage.setItem("freeFegistData", JSON.stringify(res.data.data)); //把免费试用信息存储
					sessionStorage.setItem("loginData", JSON.stringify(res.data.data));
					sessionStorage.setItem("logging", 1);
				}
			})
		}

	}

}

export default {
	getFreeFegist
}