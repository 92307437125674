import axios from 'axios';
import qs from 'qs';
const baseUrlApi = 'https://deep-slb-activity.ksbao.com/api'; //科目
let baseUrls = 'https://deep-slb-user.ksbao.com/api';//登录
const slbPayApi = 'https://hzstg.ksbao.com/mypro?url=http://deep-slb-pay.ksbao.com/api'; //充值支付

let Urls = 'http://118.178.85.6:3031'; //接口118.178.85.6：3031
const bcdata = "http://slb-pay-test.ksbao.com";
let hhh = 'http://118.178.85.6:3031/liveBroadcastingRoom/find';//预约分页接口
let maidian='http://116.62.161.214:8081';
let message_url = 'http://118.178.85.6:8080';
const slbVideoApi = 'https://deep-slb-video.ksbao.com/api'; //免费---课程

const kmjb = "http://120.26.249.60:3000"  
var  getAppKsbClassApi="http://116.62.79.97/mypro?url=http://10.94.183.7:9013/api"

var getAppKsbClassApi='https://answerconfirm.ksbao.com/free_course_activities';

//http://116.62.79.97/mypro?url=http://10.94.183.7:9013/api/tkcore/getTestNum?SessionKey=1C79CE6C3037EA1594CEA4CE3653C08B&appEName=GJZC_ZG_KQYX_YTMJ
export const setTouristsInfo = params => {
	return axios.get(`${maidian}/yearsBill/setTouristsInfo`, {
		params: params
	})
}
export const updataTouristsInfo = params => {
	return  axios.get(`${maidian}/yearsBill/updataTouristsInfo`, {
		params: params
	})
}
// 预约分页
export const getData = params => {
	return axios.get(`${Urls}/liveBroadcastingRoom/find`, {
		params: params
	})
}

// 查询直播
export const livefind = params => {
	return axios.get(`${Urls}/liveBroadcastingRoom/findClient`, {
		params: params
	})
}
// 查预约
export const findSubscribeLive = params => {
	return axios.get(`${Urls}/liveBroadcastingRoom/findSubscribeLive`, {
		params: params
	})
}
// 订单
export const vipApp = params => {
	return axios.get(`${baseUrls}/user/vipApp`, {
		params: params
	})
}
 //预约
  export const addSubscribeLive = params => {
    return axios.post(`${Urls}/liveBroadcastingRoom/addSubscribeLive`, qs.stringify(params))
  }
//获取软件目录-科目分类
export const getKsbClass = params => {
    return axios.get(`${baseUrlApi}/research/getKsbClass`, {
      params: params
    })
  }
  //获取目录-通过分类获取具体科目
  export const getKsbClassInfo = params => {
    return axios.get(`${baseUrlApi}/research/getKsbClassInfo`, {
      params: params
    })
  }
  //科目搜索
  export const getSearch = params => {
    return axios.get(`${baseUrlApi}/search/appEName`, {
      params: params
    })
  }
  //快捷注册获取短信验证码
  export const sendlogincode = params => {
    return axios.post(`${baseUrls}/user/sendlogincode`, qs.stringify(params))
  }
  //检查账号是否注册 
  export const checkUser = params => {
    return axios.get(`${baseUrls}/user/checkUser`, {
      params: params
    })
  }
  //注册获取短信验证码 https://slb-user-test.ksbao.com/api/user/sendcode
  export const sendcode = params => {
    return axios.post(`${baseUrls}/user/sendcode`, qs.stringify(params))
  }
  //获取短信验证码登录
  export const codelogin = params => {
    return axios.post(`${baseUrls}/user/codelogin`, qs.stringify(params))
  }
  //忘记密码 
  export const resetPassword = params => {
    return axios.post(`${baseUrls}/user/restorePassword`, qs.stringify(params))
  }
  //账号密码登录
  export const userlogin = params => {
    return axios.post(`${baseUrls}/user/userlogin`, qs.stringify(params))
  }
 // 充值页面列表--在线充值
 export const pricelist = params => {
   return axios.get(`${slbPayApi}/pricelist`, {
     params: params
   })
 }
 //微信支付
 export const getOrderNumber = params => {
   return axios.post(`${slbPayApi}/getOrderNumber`, qs.stringify(params), {
    
   })
  }
 // 免费试用
 export const freeFegist = params => {
   return axios.post(`${baseUrls}/user/freeFegist`, qs.stringify(params))
 }
  // 验证用户是否开通了某个科目
  export const userVipinfo = params => {
    return axios.get(`${baseUrls}/user/vipinfo`, {
      params: params
    })
  }
  //默认班次 http://slb-pay-test.ksbao.com/
   export const getCacheAppVnList = params => {
     return axios.get(`${bcdata}/api/query/getCacheAppVnList`, {
       params: params
     })
   }

  //  资讯首页
  export const getInformation = params => {
    return axios.get(`${message_url}/consulting/index`, {
      params: params
    })
  }
  //  资讯搜索
  export const Search = params => {
    return axios.get(`${message_url}/consulting/article/search`, {
      params: params
    })
  }
  //  资讯中心点击量最高文章列表接口
  export const articleLists = params => {
    return axios.get(`${message_url}/consulting/hot/article`, {
      params: params
    })
  }

  // 资讯中心文章详情接口
  export const articleDetails = params => {
    return axios.get(`${message_url}/consulting/article/detail`, {
      params: params
    })
  }
  // 获取资讯中心各系列各子栏目文章列表
  export const seriesList = params => {
    return axios.get(`${message_url}/consulting/article/list`, {
      params: params
    })
  }
  //考点精讲--课程
  export const videoCatalogue = params => {
    return axios.get(`${slbVideoApi}/video_catalogue`, {
      params: params
    })
  }
//课程--获取视频源
export const videoPlayUrl = params => {
  return axios.get(`${slbVideoApi}/video_play_url`, {
    params: params
  })
}

export const getClassID = params => {
  return axios.get(`${kmjb}/yxm/getClassID`, {
    params: params
  })
}

//科目上一级
export const getAppKsbClass = params => {
  return axios.get(`${getAppKsbClassApi}/httpController/getAppKsbClass`, {
    params: params
  })
}
//设置密码
export const changePassword = params => {
  return axios.post(`${baseUrls}/user/changePassword`, qs.stringify(params))
}
